import React, { useState, useEffect } from 'react';
import { Button, Grid, TableHead } from '@material-ui/core';
import { deleteUserRole, fetchUserRoles } from '../../Components/API';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import ConfirmationDialog from './ConfirmationDialog';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import SearchBarHeader from './SearchBarHeader';
import {
  columnsUserRoles,
  itemNames,
  NoDataTable,
  TableFooterCustom,
  TableHeader,
} from './Common';
import AddItems from './AddItems';
import { BRANDS_ENUM } from '../../Components/Signature/assets/brands';

const useStyles = makeStyles(theme =>
  createStyles({
    buttonModify: theme.typography.mainButton,
    buttonDelete: theme.typography.secondaryButton,
  })
);

const ListUserRoles = ({ lambdaUrl }) => {
  const [loading, setLoading] = useState(false);
  const [searchVal, setSearchVal] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [listUserRoles, setListUserRoles] = useState(null);
  const [listUserRolesInUse, setListUserRolesInUse] = useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [idUserRoleToDelete, setIdUserRoleToDelete] = useState(null);

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  useEffect(() => {
    setLoading(true);
    if (lambdaUrl) {
      fetchUserRoles(lambdaUrl, setListUserRoles)
        .then(res => {
          setListUserRoles(res);
          setListUserRolesInUse(res);
          setLoading(false);
        })
        .catch(err => {
          console.log('Error fetching list user roles : ', err);
          setLoading(false);
        });
    }
  }, [lambdaUrl]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openConfirmDialog = idUserRole => {
    setIdUserRoleToDelete(idUserRole);
    setShowConfirmDialog(true);
  };
  const closeConfirmationDialog = () => {
    setShowConfirmDialog(false);
  };

  const submitDelete = () => {
    deleteUserRole(lambdaUrl, idUserRoleToDelete)
      .then(() => {
        fetchUserRoles(lambdaUrl, setListUserRoles).then(res => {
          setListUserRoles(res);
          setListUserRolesInUse(res);
          enqueueSnackbar('Donnéee supprimée avec succes!', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        });
        setShowConfirmDialog(false);
      })
      .catch(err => {
        console.log('Error fetching list user roles : ', err);
        setShowConfirmDialog(false);
        enqueueSnackbar(
          'Une erreur est survenu lors de la tentative de supprimer la donnée!',
          {
            variant: 'error',
            autoHideDuration: 3000,
          }
        );
      });
  };

  const handleSearch = searchVal => {
    if (!listUserRoles) {
      return;
    }
    return listUserRoles.filter(row => {
      return (
        row.foncia_id.toLowerCase().includes(searchVal.toLowerCase()) ||
        row.nom.toLowerCase().includes(searchVal.toLowerCase()) ||
        row.prenom.toLowerCase().includes(searchVal.toLowerCase()) ||
        row.role.toLowerCase().includes(searchVal.toLowerCase())
      );
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SearchBarHeader
          searchVal={searchVal}
          setSearchVal={setSearchVal}
          handleSearch={handleSearch}
          setListInUse={setListUserRolesInUse}
        />
        <Grid item xs={12}>
          <AddItems
            itemName={itemNames.userRole}
            lambdaUrl={lambdaUrl}
            setList={setListUserRoles}
            setListInUse={setListUserRolesInUse}
          />
        </Grid>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableHeader columns={columnsUserRoles} />
            </TableHead>
            <TableBody>
              {listUserRolesInUse && listUserRolesInUse.length ? (
                listUserRolesInUse
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(userRole => (
                    <TableRow key={userRole.id}>
                      <TableCell align="center">{userRole.id}</TableCell>
                      <TableCell align="center">{userRole.foncia_id}</TableCell>
                      <TableCell align="center">{userRole.nom}</TableCell>
                      <TableCell align="center">{userRole.prenom}</TableCell>
                      <TableCell align="center">{userRole.role}</TableCell>
                      <TableCell align="center">
                        {BRANDS_ENUM[userRole.brand]}
                      </TableCell>
                      <TableCell align="center">
                        {userRole.actif.toString()}
                      </TableCell>
                      <TableCell align="center">
                        {userRole.date_maj
                          ? userRole.date_maj.replace(/[TZ]/g, ' ')
                          : ''}
                      </TableCell>
                      <TableCell align="center">
                        <Link
                          to={{
                            pathname: 'admin/modifierUserRole/' + userRole.id,
                            params: {
                              userRole: userRole,
                              lambdaUrl: lambdaUrl,
                            },
                          }}
                          style={{ textDecoration: 'none' }}
                        >
                          <Button
                            className={classes.buttonModify}
                            variant="contained"
                          >
                            Modifier
                          </Button>
                        </Link>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          className={classes.buttonDelete}
                          variant="contained"
                          onClick={() => openConfirmDialog(userRole.id)}
                        >
                          Supprimer
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <NoDataTable loading={loading} />
              )}
            </TableBody>
            <TableFooter>
              <TableFooterCustom
                list={listUserRoles}
                rowsPerPage={rowsPerPage}
                page={page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableFooter>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={6}>
        <ConfirmationDialog
          visible={showConfirmDialog}
          text="Voulez-vous supprimer la ligne ?"
          onConfirm={submitDelete}
          onCancel={closeConfirmationDialog}
        />
      </Grid>
    </Grid>
  );
};

export default ListUserRoles;
