import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { MenuItem, TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { BRANDS_ENUM } from '../../Components/Signature/assets/brands';

const useStyles = makeStyles(theme =>
  createStyles({
    headerContainer: {
      fontSize: 12,
      fontWeight: 700,
      color: theme.palette.white.main,
      backgroundColor: theme.palette.black.main,
    },
  })
);

export const TableHeader = ({ columns }) => {
  const classes = useStyles();
  return (
    <TableRow>
      {columns.map(col => (
        <TableCell
          key={col.dataField}
          align="center"
          className={classes.headerContainer}
        >
          {col.text}
        </TableCell>
      ))}
    </TableRow>
  );
};

export const NoDataTable = ({ loading }) => {
  return (
    <TableRow key={'undefined'}>
      {loading ? (
        <TableCell colSpan={12} align="left">
          Chargement en cours...{' '}
        </TableCell>
      ) : (
        <TableCell colSpan={12} align="left">
          Pas de donnée{' '}
        </TableCell>
      )}
    </TableRow>
  );
};

export const TableFooterCustom = ({
  list,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  return (
    <TableRow>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        colSpan={12}
        count={list ? list.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage="Ligne(s) par page"
        nextIconButtonText="Suivant"
        backIconButtonText="Précédente"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count} lignes`
        }
      />
    </TableRow>
  );
};

export const columnsLecteurs = [
  {
    dataField: 'id',
    text: 'Identification',
  },
  {
    dataField: 'type',
    text: 'Type',
  },
  {
    dataField: 'type_detail',
    text: 'Détails',
  },
  {
    dataField: 'lecteur_reseau',
    text: 'Lecteur Réseau',
  },
  {
    dataField: 'libelle',
    text: 'Libellé',
  },
  {
    dataField: 'valide',
    text: 'Valide',
  },
  {
    dataField: 'date_creation',
    text: 'Date de création',
  },
  {
    dataField: 'date_maj',
    text: 'Date de modification',
  },
  {
    dataField: 'modif',
    text: 'Modifier',
  },
  {
    dataField: 'supprimer',
    text: 'Supprimer',
  },
];

export const columnsUserRoles = [
  {
    dataField: 'id',
    text: 'Identification',
  },
  {
    dataField: 'foncia_id',
    text: 'Identification Foncia',
  },
  {
    dataField: 'nom',
    text: 'Nom',
  },
  {
    dataField: 'prenom',
    text: 'Prénom',
  },
  {
    dataField: 'role',
    text: 'Rôle',
  },
  {
    dataField: 'brand',
    text: 'Marque',
  },
  {
    dataField: 'actif',
    text: 'Actif',
  },
  {
    dataField: 'date_maj',
    text: 'Date de modification',
  },
  {
    dataField: 'modif',
    text: 'Modifier',
  },
  {
    dataField: 'supprimer',
    text: 'Supprimer',
  },
];

export const itemNames = {
  lecteur: 'LECTEUR RESEAU',
  userRole: 'USER ROLE',
};

export const roles = [
  {
    value: 'ADMIN',
    label: 'Admin',
  },
  {
    value: 'MANAGER_LECTEUR_RESEAU',
    label: 'Manager lecteur réseau',
  },
  {
    value: 'MANAGER_SIGNATURE',
    label: 'Manager signature',
  },
];

export const typeLecteurs = [
  {
    value: 'user',
    label: 'Utilisateur',
  },
  {
    value: 'group',
    label: 'Groupe',
  },
];

export const addLecteur = [
  {
    idField: 'type',
    label: 'Type',
    helperText: 'Type de lecteur',
    component: SelectField,
    options: typeLecteurs,
  },
  {
    idField: 'type_detail',
    label: 'Détail',
    helperText: 'Détail du type',
  },
  {
    idField: 'lecteur_reseau',
    label: 'Lecteur Réseau',
    helperText: 'Lecteur Réseau',
  },
  {
    idField: 'libelle',
    label: 'Libellé',
    helperText: 'Libellé',
  },
];

export const actif = [
  {
    value: true,
    label: 'Oui',
  },
  {
    value: false,
    label: 'Non',
  },
];

const defaultBrandValues = Object.keys(BRANDS_ENUM).map(brandKey => ({
  value: brandKey,
  label: BRANDS_ENUM[brandKey],
}));

export const addUserRole = [
  {
    idField: 'fonciaId',
    label: 'Identification',
    helperText: 'Identification',
  },
  {
    idField: 'nom',
    label: 'Nom',
    helperText: 'Nom',
  },
  {
    idField: 'prenom',
    label: 'Prénom',
    helperText: 'Prénom',
  },
  {
    idField: 'role',
    label: 'Rôle',
    helperText: 'Choisir un rôle',
    component: SelectField,
    options: roles,
    defaultValue: roles[0].value,
  },
  {
    idField: 'brand',
    label: 'Marques',
    helperText: 'Choisir une marque',
    component: SelectField,
    options: defaultBrandValues,
    defaultValue: defaultBrandValues[0].value,
    isHidden: newUser => newUser?.role !== 'MANAGER_SIGNATURE',
    required: true,
  },
];

export function SelectField(props) {
  const {
    value,
    options,
    handleChange,
    className,
    label,
    helperText,
    required,
  } = props;

  const handleChangeInput = event => {
    handleChange(event.target.value);
  };
  return (
    <Grid item xs={12}>
      <TextField
        value={value}
        select
        label={label}
        onChange={handleChangeInput}
        helperText={helperText}
        className={className}
        required={required}
      >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Grid>
  );
}

export const updateLecteurColumns = [
  {
    idField: 'id',
    label: 'Identification',
    readOnly: true,
  },
  {
    idField: 'type',
    label: 'Type',
    readOnly: true,
  },
  {
    idField: 'type_detail',
    label: 'Détails',
    readOnly: false,
  },
  {
    idField: 'lecteur_reseau',
    label: 'Lecteur Réseau',
    readOnly: false,
  },
  {
    idField: 'libelle',
    label: 'Libellé',
    readOnly: false,
  },
  {
    idField: 'valide',
    label: 'Valide',
    readOnly: true,
    component: SelectField,
    options: actif,
  },
  {
    idField: 'date_creation',
    label: 'Date de création',
    readOnly: true,
  },
  {
    idField: 'date_maj',
    label: 'Date de modification',
    readOnly: true,
  },
];

export const updateUserRoleColumns = [
  {
    idField: 'id',
    label: 'Identification',
    readOnly: true,
  },
  {
    idField: 'foncia_id',
    label: 'Identification Foncia',
    readOnly: true,
  },
  {
    idField: 'nom',
    label: 'Nom',
    readOnly: false,
  },
  {
    idField: 'prenom',
    label: 'Prénom',
    readOnly: false,
  },
  {
    idField: 'role',
    label: 'Rôle',
    readOnly: false,
    component: SelectField,
    options: roles,
  },
  {
    idField: 'brand',
    label: 'Marques',
    readOnly: false,
    component: SelectField,
    options: defaultBrandValues,
    isHidden: newUser => newUser?.role !== 'MANAGER_SIGNATURE',
  },
  {
    idField: 'actif',
    label: 'Actif',
    readOnly: false,
    component: SelectField,
    options: actif,
  },
  {
    idField: 'date_maj',
    label: 'Date de modification',
    readOnly: true,
  },
];
