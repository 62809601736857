import React from "react";
import SearchBar from "material-ui-search-bar";
import {Grid} from "@material-ui/core";
import {createStyles, makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
	createStyles({
		searchBarContainer: {
			padding: "10px 0 10px 0"
		},
		searchBar: {
			backgroundColor: theme.palette.gray.light
		}
	})
);

const SearchBarHeader = ({searchVal, setSearchVal, handleSearch, setListInUse}) => {
	const classes = useStyles();

	const requestSearch = (searchVal) => {
		const filteredList = handleSearch(searchVal);
		setListInUse(filteredList);
	};

	const cancelSearch = () => {
		setSearchVal("");
		handleSearch(searchVal);
	};
	
	return(
		<Grid item xs={12} className={classes.searchBarContainer}>
			<SearchBar
				className={classes.searchBar}
				placeholder={"Recherche..."}
				value={searchVal}
				onChange={(searchVal) => requestSearch(searchVal)}
				onCancelSearch={() => cancelSearch()}
			/>
		</Grid>
	);
}

export default SearchBarHeader;