import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  CircularProgress,
  Divider,
  IconButton,
  LinearProgress,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";

import FolderIcon from "@material-ui/icons/Folder";
import CustomFolder from "@material-ui/icons/WbCloudy";
import LaunchIcon from "@material-ui/icons/Launch";

const useStyles = makeStyles((theme) => ({
  listElement: {
    "&:hover": {
      background: theme.palette.secondary.dark,
    },
  },
}));

const groupNames = ["defaultGroups", "customGroups"];
const Repertoire = ({ rep, link, index, repertoiresLength, group }) => {
  const classes = useStyles();
  return (
    <div key={rep.id} className={classes.listElement}>
      <ListItem component="a" href={link}>
        <ListItemAvatar>
          <Avatar>
            {rep.nomUsage ? (
              group === groupNames[0] ? (
                <FolderIcon />
              ) : (
                <CustomFolder />
              )
            ) : (
              <CircularProgress color="secondary" />
            )}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={rep.nomUsage ? rep.nomUsage : `Code société : ${rep.id}`}
          secondary={
            rep.refSIGestion
              ? group === groupNames[0]
                ? `Code société : ${rep.refSIGestion}`
                : ""
              : "Chargement..."
          }
        />
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="Ouvrir le répertoire distant"
            color="secondary"
            href={link}
          >
            <LaunchIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      {index + 1 < repertoiresLength &&
        (rep.refSIGestion ? <Divider /> : <LinearProgress color="secondary" />)}
    </div>
  );
};

Repertoire.propTypes = {
  rep: PropTypes.object.isRequired,
  link: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  repertoiresLength: PropTypes.number.isRequired,
};

export default Repertoire;
