const STATIC_BRAND_PATH = `${process.env.PUBLIC_URL}/static/logos/brands`;
const STATIC_SOCIAL_PATH = `${process.env.PUBLIC_URL}/static/logos/socials`;

export const BRANDS_ENUM = {
  FONCIA: 'Foncia',
  EMERIA: 'Emeria',
  EMERIA_TECH: 'Emeria Tech',
  FONCIA_IMMO_NEUF: 'Foncia Immo Neuf',
  CONSTATIMMO: 'Constatimmo',
  ESSET_VALORISATION: 'Esset Valorisation',
  ESSET_PROPERTY: 'Esset Property Management',
  STARES: 'Stares',
  ASSURIMO: 'Assurimo',
  TECH_WAY: 'Tech Way',
  IMMO_CORSE: 'Immo Corse',
  GSI: 'GSI',
  FONCIA_VACANCES: 'Foncia Vacances'
};

export const brands = [
  {
    id: BRANDS_ENUM.FONCIA,
    alt: 'Logo de Foncia standard',
    src: `${STATIC_BRAND_PATH}/foncia.png`,
    size: {
      width: 134,
      height: 117,
      mobileRatio: 1.21,
    },
    canChooseBanner: true,
    metadata: {
      property: {
        name: {
          style: {
            color: '#033f81',
          },
        },
        position: {
          style: {
            color: '#ed6c0a',
          },
        },
        agency: {
          style: {
            color: '#033f81',
          },
        },
        mail: {
          style: {
            color: '#033f81',
          },
        },
        body: {
          style: {
            color: '#033f81',
          },
        },
        website: {
          url: 'https://fr.foncia.com',
          style: {
            color: '#033f81',
          },
        },
      },
      bottomBorderColor: '#ed6c0a',
      socials: [
        {
          type: 'facebook',
          url: 'https://www.facebook.com/Foncia/',
          logo: `${STATIC_SOCIAL_PATH}/foncia/facebook.png`,
        },
        {
          type: 'twitter',
          url: 'https://twitter.com/foncia',
          logo: `${STATIC_SOCIAL_PATH}/foncia/twitter.png`,
        },
        {
          type: 'linkedin',
          url: 'https://www.linkedin.com/company/foncia',
          logo: `${STATIC_SOCIAL_PATH}/foncia/linkedin.png`,
        },
        {
          type: 'instagram',
          url: 'https://www.instagram.com/foncia/',
          logo: `${STATIC_SOCIAL_PATH}/foncia/instagram.png`,
        },
        {
          type: 'youtube',
          url: 'https://www.youtube.com/user/foncia',
          logo: `${STATIC_SOCIAL_PATH}/foncia/youtube.png`,
        },
      ],
    },
  },
  {
    id: BRANDS_ENUM.EMMERIA,
    alt: 'Logo de Foncia Emeria',
    src: `${STATIC_BRAND_PATH}/emeria.png`,
    size: {
      width: 177,
      height: 85,
      mobileRatio: 1.21,
    },
    canChooseBanner: false,
    metadata: {
      property: {
        image: {
          style: {
            verticalAlign: 'top',
            paddingRight: '43pt',
          },
        },
        name: {
          bottomMargin: true,
          style: {
            color: 'rgb(0, 25, 130)',
            fontSize: '10pt',
          },
        },
        position: {
          style: {
            color: 'rgb(0, 25, 130)',
            fontSize: '10pt',
          },
        },
        agency: {
          style: {
            color: 'rgb(12, 20, 64)',
            fontWeight: 'initial',
            fontSize: '8pt',
          },
        },
        mail: {
          style: {
            fontSize: '8pt',
          },
        },
        body: {
          style: {
            color: 'rgb(12, 20, 64)',
            fontSize: '8pt',
          },
        },
        website: {
          url: 'https://emeria.eu',
          style: {
            color: 'rgb(12, 20, 64)',
            fontSize: '8.5pt',
          },
        },
        socials: {
          topMargin: true,
        },
      },
      socials: [
        {
          type: 'twitter',
          url: 'https://twitter.com/Emeria_eu',
          logo: `${STATIC_SOCIAL_PATH}/emeria/twitter.png`,
        },
        {
          type: 'linkedin',
          url: 'https://www.linkedin.com/company/emeria-eu/',
          logo: `${STATIC_SOCIAL_PATH}/emeria/linkedin.png`,
        },
      ],
    },
  },
  {
    id: BRANDS_ENUM.EMERIA_TECH,
    alt: 'Logo de Emeria Technologies',
    src: `${STATIC_BRAND_PATH}/emeria-tech.png`,
    size: {
      width: 177,
      height: 105,
      mobileRatio: 1.21,
    },
    canChooseBanner: false,
    metadata: {
      property: {
        image: {
          style: {
            verticalAlign: 'top',
            paddingRight: '43pt',
          },
        },
        name: {
          bottomMargin: true,
          style: {
            color: 'rgb(0, 25, 130)',
            fontSize: '10pt',
          },
        },
        position: {
          style: {
            color: 'rgb(0, 25, 130)',
            fontSize: '10pt',
          },
        },
        agency: {
          style: {
            color: 'rgb(12, 20, 64)',
            fontWeight: 'initial',
            fontSize: '8pt',
          },
        },
        mail: {
          style: {
            fontSize: '8pt',
          },
        },
        body: {
          style: {
            color: 'rgb(12, 20, 64)',
            fontSize: '8pt',
          },
        },
        website: {
          url: 'https://emeria.eu',
          style: {
            color: 'rgb(12, 20, 64)',
            fontSize: '8.5pt',
          },
        },
        socials: {
          topMargin: true,
        },
      },
      socials: [
        {
          type: 'twitter',
          url: 'https://twitter.com/Emeria_eu',
          logo: `${STATIC_SOCIAL_PATH}/emeria/twitter.png`,
        },
        {
          type: 'linkedin',
          url: 'https://www.linkedin.com/company/emeria-eu/',
          logo: `${STATIC_SOCIAL_PATH}/emeria/linkedin.png`,
        },
      ],
    },
  },
  {
    id: BRANDS_ENUM.FONCIA_IMMO_NEUF,
    alt: 'Logo de Foncia Immo neuf',
    src: `${STATIC_BRAND_PATH}/foncia-immo-neuf.png`,
    size: {
      width: 130,
      height: 130,
      mobileRatio: 1.21,
    },
    canChooseBanner: true,
    metadata: {
      property: {
        name: {
          style: {
            color: '#003c7a',
            fontFamily: 'Arial',
            fontWeight: 'bold',
            fontSize: '8pt',
          },
        },
        position: {
          style: {
            color: '#1fb7bc',
            fontFamily: 'Arial',
            fontWeight: 'regular',
            fontSize: '8pt',
          },
        },
        mail: {
          style: {
            color: '#003c7a',
            fontFamily: 'Arial',
            fontWeight: 'regular',
            fontSize: '8pt',
          },
        },
        body: {
          style: {
            color: '#003c7a',
            fontFamily: 'Arial',
            fontWeight: 'regular',
            fontSize: '8pt',
          },
        },
        website: {
          url: 'http://Foncia-immoneuf.com',
          style: {
            color: '#003c7a',
            fontFamily: 'Arial',
            fontWeight: 'bold',
            fontSize: '8pt',
          },
        },
      },
      bottomBorderColor: '#1fb7bc',
      size: {
        width: 130,
        height: 130,
        mobileRatio: 1.21,
      },
      socials: [
        {
          type: 'linkedin',
          url: 'https://fr.linkedin.com/company/foncia-immo-neuf',
          logo: `${STATIC_SOCIAL_PATH}/foncia-immo-neuf/linkedin.png`,
        },
        {
          type: 'facebook',
          url: 'https://www.facebook.com/Foncia/',
          logo: `${STATIC_SOCIAL_PATH}/foncia-immo-neuf/facebook.png`,
        },
        {
          type: 'twitter',
          url: 'https://twitter.com/foncia',
          logo: `${STATIC_SOCIAL_PATH}/foncia-immo-neuf/twitter.png`,
        },
      ],
    },
  },
  {
    id: BRANDS_ENUM.CONSTATIMMO,
    alt: 'Logo de Constatimmo',
    src: `${STATIC_BRAND_PATH}/constatimmo.png`,
    size: {
      width: 205,
      height: 135,
      mobileRatio: 1.21,
    },
    canChooseBanner: true,
    metadata: {
      property: {
        name: {
          style: {
            color: '#004327',
          },
        },
        position: {
          style: {
            color: '#14925B',
            lineHeight: '20px',
          },
        },
        mail: {
          style: {
            color: '#004327',
            lineHeight: '20px',
          },
        },
        body: {
          style: {
            color: '#004327',
            lineHeight: '20px',
          },
        },
        website: {
          url: 'https://www.constatimmo.com',
          style: {
            textTransform: 'uppercase',
            color: '#004327',
            lineHeight: '20px',
          },
        },
      },
      socials: [
        {
          type: 'linkedin',
          url: 'https://www.linkedin.com/company/constatimmo',
          logo: `${STATIC_SOCIAL_PATH}/constatimmo/linkedin.png`,
          sizeRatio: 1.5,
        },
      ],
    },
  },
  {
    id: BRANDS_ENUM.STARES,
    alt: 'Logo de Stares',
    src: `${STATIC_BRAND_PATH}/stares.png`,
    size: {
      width: 130,
      height: 88,
      mobileRatio: 1.21,
    },
    canChooseBanner: true,
    metadata: {
      customTemplate: BRANDS_ENUM.STARES,
      property: {
        image: {
          style: {
            borderRight: '1px solid #2DB7BC',
          },
        },
        website: {
          url: 'https://stares.fr',
          style: {
            color: '#2DB7BC',
            fontWeight: 'normal',
            fontFamily: 'verdana',
            textDecoration: 'underline',
          },
        },
      },
      socials: [
        {
          type: 'linkedin',
          url: 'https://fr.linkedin.com/company/stares-france',
          logo: `${STATIC_SOCIAL_PATH}/stares/linkedin.png`,
          sizeRatio: 2,
        },
      ],
    },
  },
  {
    id: BRANDS_ENUM.ASSURIMO,
    alt: 'Logo de Assurimo',
    src: `${STATIC_BRAND_PATH}/assurimo.png`,
    miniature: `${STATIC_BRAND_PATH}/assurimo-miniature.png`,
    size: {
      width: 180,
      height: 45,
      mobileRatio: 1,
    },
    canChooseBanner: true,
    metadata: {
      customTemplate: BRANDS_ENUM.ASSURIMO,
      property: {
        image: {
          style: {
            borderLeft: '2px solid #FFD396',
          },
        },
        website: {
          url: 'https://www.assurimo.fr',
          style: {
            color: '#0D3788',
          },
        },
      },
      socials: [
        {
          type: 'linkedin',
          url: 'https://fr.linkedin.com/company/assurimo-paris',
          logo: `${STATIC_SOCIAL_PATH}/assurimo/linkedin.png`,
        },
      ],
    },
  },
  {
    id: BRANDS_ENUM.ESSET_VALORISATION,
    alt: 'Logo de Esset Valorisation',
    src: `${STATIC_BRAND_PATH}/esset-valorisation.png`,
    size: {
      width: 175,
      height: 142,
      mobileRatio: 1.21,
    },
    canChooseBanner: true,
    metadata: {
      property: {
        image: {
          style: {
            paddingRight: '20pt',
          },
        },
        name: {
          style: {
            color: '#000000',
          },
        },
        position: {
          style: {
            color: '#d1b578',
            fontWeight: 'bold',
          },
        },
        mail: {
          style: {
            color: '#000000',
          },
        },
        body: {
          style: {
            color: '#000000',
          },
        },
        website: {
          url: 'https://esset-valorisation.com',
          style: {
            color: '#000000',
            textDecoration: 'underline',
          },
        },
      },
      socials: [
        {
          type: 'linkedin',
          url: 'https://fr.linkedin.com/company/essetvalorisation',
          logo: `${STATIC_SOCIAL_PATH}/esset-valorisation/linkedin.png`,
        },
      ],
    },
  },
  {
    id: BRANDS_ENUM.ESSET_PROPERTY,
    alt: 'Logo de Esset Property Management',
    src: `${STATIC_BRAND_PATH}/esset-property.png`,
    size: {
      width: 175,
      height: 142,
      mobileRatio: 1.21,
    },
    canChooseBanner: true,
    metadata: {
      customTemplate: BRANDS_ENUM.ESSET_PROPERTY,
      property: {
        image: {
          style: {
            paddingRight: '20pt',
          },
        },
        name: {
          style: {
            color: '#000000',
            fontSize: '10pt',
            lineHeight: '14pt',
            fontFamily: 'arial',
          },
        },
        position: {
          style: {
            color: '#5eb991',
            fontWeight: 'bold',
            fontSize: '10pt',
            lineHeight: '14pt',
            fontFamily: 'arial',
          },
        },
        mail: {
          style: {
            color: '#000000',
            fontSize: '10pt',
            lineHeight: '14pt',
            fontFamily: 'arial',
          },
        },
        body: {
          style: {
            color: '#000000',
            fontSize: '10pt',
            lineHeight: '14pt',
            fontFamily: 'arial',
          },
        },
        website: {
          url: 'https://esset-pm.com',
          style: {
            color: '#000000',
            fontSize: '10pt',
            lineHeight: '14pt',
            fontFamily: 'arial',
            fontWeight: 'normal',
            textDecoration: 'underline',
          },
        },
        socials: {
          style: {
            lineHeight: '20pt',
            display: 'inline-block',
            verticalAlign: 'middle',
          },
        },
      },
      socials: [
        {
          type: 'linkedin',
          url: 'https://fr.linkedin.com/company/esset-property-management',
          logo: `${STATIC_SOCIAL_PATH}/esset-property/linkedin.png`,
          sizeRatio: 1.5,
          style: {},
        },
      ],
    },
  },
  {
    id: BRANDS_ENUM.TECH_WAY,
    alt: 'Logo de tech way',
    src: `${STATIC_BRAND_PATH}/techway.png`,
    size: {
      width: 130,
      height: 113,
      mobileRatio: 1.21,
    },
    canChooseBanner: true,
    metadata: {
      customTemplate: BRANDS_ENUM.TECH_WAY,
      property: {
        image: {
          style: {
            paddingRight: '20pt',
          },
        },
        website: {
          url: '',
        },
      },
      socials: [],
    },
  },
  {
    id: BRANDS_ENUM.IMMO_CORSE,
    alt: 'Logo de immo corse',
    src: `${STATIC_BRAND_PATH}/immo-corse.png`,
    size: {
      width: 180,
      height: 132,
      mobileRatio: 1.21,
    },
    canChooseBanner: true,
    metadata: {
      customTemplate: BRANDS_ENUM.IMMO_CORSE,
      property: {
        image: {
          style: {
            paddingRight: '43pt',
          },
        },
        website: {
          url: '',
        },
      },
      socials: [],
    },
  },
  {
    id: BRANDS_ENUM.GSI,
    alt: 'Logo de GSI',
    src: `${STATIC_BRAND_PATH}/gsi.png`,
    size: {
      width: 134,
      height: 117,
      mobileRatio: 1.21,
    },
    canChooseBanner: true,
    metadata: {
      property: {
        name: {
          style: {
            color: '#204060',
            fontFamily: 'Arial',
            fontSize: '8pt',
          },
        },
        position: {
          style: {
            color: '#204060',
            fontFamily: 'Arial',
            fontSize: '8pt',
          },
        },
        agency: {
          style: {
            color: '#204060',
            fontFamily: 'Arial',
            fontSize: '8pt',
          },
        },
        mail: {
          style: {
            color: '#204060',
            fontFamily: 'Arial',
            fontSize: '8pt',
          },
        },
        body: {
          style: {
            color: '#204060',
            fontFamily: 'Arial',
            fontSize: '8pt',
          },
        },
        website: {
          url: 'https://gsi-immobilier.com',
          style: {
            color: '#204060',
            fontFamily: 'Arial',
            fontSize: '8pt',
            textDecoration: 'none',
          },
        },
        socials: {
          style: {
            marginTop: '4pt',
            marginBottom: '6pt',
            fontFamily: 'Arial',
            fontSize: '8pt',
          }
        },
      },
      bottomBorderColor: '#204060',
      socials: [
        {
          type: 'facebook',
          url: 'https://www.facebook.com/gsi.by.foncia',
          logo: `${STATIC_SOCIAL_PATH}/gsi/facebook.png`,
        },
        {
          type: 'instagram',
          url: 'https://www.instagram.com/gsibyfoncia/',
          logo: `${STATIC_SOCIAL_PATH}/gsi/instagram.png`,
        },
        {
          type: 'linkedin',
          url: 'https://www.linkedin.com/company/gsi-immobilier',
          logo: `${STATIC_SOCIAL_PATH}/gsi/linkedin.png`,
        },
        
      ],
    },
  },
  {
    id: BRANDS_ENUM.FONCIA_VACANCES,
    alt: 'Logo de Foncia standard pour vacances foncia',
    src: `${STATIC_BRAND_PATH}/vacances-foncia.png`,
    size: {
      width: 134,
      height: 117,
      mobileRatio: 1.21,
    },
    canChooseBanner: true,
    metadata: {
      customTemplate: BRANDS_ENUM.FONCIA_VACANCES,
      property: {
        name: {
          style: {
            color: '#033f81',
            fontFamily: 'Arial',
            fontSize: '8pt',
          },
        },
        position: {
          style: {
            color: '#ffb81c',
            fontFamily: 'Arial',
            fontSize: '8pt',
          },
        },
        agency: {
          style: {
            color: '#033f81',
            fontFamily: 'Arial',
            fontSize: '8pt',
          },
        },
        mail: {
          style: {
            color: '#033f81',
            fontFamily: 'Arial',
            fontSize: '8pt',
          },
        },
        body: {
          style: {
            color: '#033f81',
            fontFamily: 'Arial',
            fontSize: '8pt',
          },
        },
        website: {
          url: 'https://vacances.foncia.com',
          content:'<span style="color:#ffb81c">vacances.</span><span class="#033f81">foncia</span>',
          style: {
            textDecoration: 'none',
            lineHeight: '8pt',
            fontFamily: 'Arial',
            fontSize: '8pt',
          },
        },
        socials: {
          style: {
            fontFamily: 'Arial',
            fontSize: '8pt',
          }
        },
      },
      bottomBorderColor: '#ed6c0a',
      socials: [
        {
          type: 'facebook',
          url: 'https://www.facebook.com/Foncia/',
          logo: `${STATIC_SOCIAL_PATH}/foncia/facebook.png`,
        },
        {
          type: 'twitter',
          url: 'https://twitter.com/foncia',
          logo: `${STATIC_SOCIAL_PATH}/foncia/twitter.png`,
        },
        {
          type: 'linkedin',
          url: 'https://www.linkedin.com/company/foncia',
          logo: `${STATIC_SOCIAL_PATH}/foncia/linkedin.png`,
        },
      ],
    },
  },
];
