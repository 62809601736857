const desktopLinks = [
  {
    url: "/repertoires",
    name: "Lecteurs réseau",
  },
  {
    url: "/signatures",
    name: "Mes signatures",
  },
  {
    url: "/admin",
    name: "Administration",
  },
];

const mobileLinks = [
  {
    url: "/repertoires",
    name: "Lecteurs réseau",
  },
  {
    url: "/signatures",
    name: "Mes signatures",
  },
  {
    url: "/admin",
    name: "Administration",
  },
];

export { desktopLinks, mobileLinks };
