import React from 'react';
import { Span, A } from 'react-html-email';
import { formatUrlForSignatures, getLineHeight } from '../utils';

export default function Website({ targetIsApp, property }) {
  return (
    <div
      style={{
        lineHeight: getLineHeight(
          targetIsApp,
          property?.website?.style?.lineHeight
        ),
      }}
    >
      <Span fontSize={11}>
        <A
          href={property?.website.url}
          textDecoration={property?.website?.style?.textDecoration ?? 'none'}
        >
          <strong
            style={{
              fontWeight: 'bold',
              ...property?.website?.style,
            }}
          >
            {formatUrlForSignatures(property?.website.url)}
          </strong>
        </A>
      </Span>
    </div>
  );
}
