import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import { Button, Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import { desktopLinks } from "./utils";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) =>
  createStyles({
    homeButton: {
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(1),
      fontSize: "30px",
      color: theme.palette.white.main,
    },
    button: {
      fontWeight: "bold",
    },
  })
);

const ADMIN = {
  admin: "ADMIN",
  managerLecteurReaseau: "MANAGER_LECTEUR_RESEAU",
};

const isAdmin = (user) => {
  if (!user || !user.roles) {
    return false;
  }
  let res = user.roles.filter((role) => {
    return ADMIN.admin === role || ADMIN.managerLecteurReaseau === role;
  });
  return res.length > 0;
};

const DesktopMenu = ({ user, versionNumber }) => {
  const classes = useStyles();
  // const handleMenu = (event) => {
  //   console.log("clicked");
  // };
  return (
    <>
      <Tooltip title={`Numéro de version : ${versionNumber}`}>
        <Button component={Link} to="/" className={classes.homeButton}>
          Apple Helper
        </Button>
      </Tooltip>
      {desktopLinks.map(({ url, name }) =>
        url !== "/admin" ? (
          <Button
            className={classes.button}
            color="secondary"
            key={url}
            component={Link}
            to={url}
          >
            {name}
          </Button>
        ) : isAdmin(user) ? (
          <Button
            className={classes.button}
            color="secondary"
            key={url}
            component={Link}
            to={url}
          >
            {name}
          </Button>
        ) : null
      )}
      {/* <Link color="inherit" to="/infos">
        <IconButton
          aria-label="Informations personnelles"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        Mes infos
      </Link> */}
    </>
  );
};

DesktopMenu.propTypes = {
  user: PropTypes.shape({
    family_name: PropTypes.string.isRequired,
    given_name: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    preferred_username: PropTypes.string.isRequired,
    roles: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    sub: PropTypes.string.isRequired,
    updated_at: PropTypes.number.isRequired,
    zoneinfo: PropTypes.string.isRequired,
  }),
};

export default DesktopMenu;
