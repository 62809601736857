import React from 'react';
import { Image as ImageMail } from 'react-html-email';

export default function Image({ property, selectedBrand, isMobile }) {
  const { size } = selectedBrand;
  return (
    <td
      align="center"
      style={{
        paddingRight: '15pt',
        ...property?.image?.style,
      }}
    >
      <ImageMail
        style={{
          minHeight: 'auto',
          minWidth: 'auto',
          marginTop: '0px',
        }}
        src={selectedBrand.src}
        width={isMobile ? size.width * size.mobileRatio : size.width}
        height={isMobile ? size.height * size.mobileRatio : size.height}
        alt={`${selectedBrand.alt}`}
      />
    </td>
  );
}
