import React, { useState, useEffect } from "react";
import {
  Button,
  Divider,
  Grid,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import ListUserRoles from "./ListUserRoles";
import ListLecteurReseaux from "./ListLecteurReseaux";
import { useConfFetch } from "../../hooks/useConfFetch";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { useOktaAuth } from "@okta/okta-react";
import { fetchUserRoleFromId } from "../../Components/API";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      margin: theme.spacing(1, 0, 0),
    },
    containers: {
      backgroundColor: theme.palette.background.paper,
      justifyContent: "center",
    },
    buttonMain: theme.typography.mainButton,
    buttonDetails: {
      fontWeight: "bold",
      margin: "10px 10px 10px 0",
    },
  })
);

const UserRoleModifyRights = ["ADMIN"];

const LecteurModifyRights = ["ADMIN", "MANAGER_LECTEUR_RESEAU"];

const Administration = ({ userAuth }) => {
  const history = useHistory();
  const { oktaAuth, authState } = useOktaAuth();
  const [chosenTable, setChosenTable] = useState("Lecteurs Réseaux");
  const [fonciaId, setFonciaId] = useState(null);
  const [lambdaUrl, setLambdaUrl] = useState(null);
  const [showUserRole, setShowUserRole] = useState(false);
  const [conf, error, loading] = useConfFetch();
  const [hasAccess, setHasAccess] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      let user = await oktaAuth.getUser();
      const fonciaId = /fr(x|)[0-9]*/.exec(user.preferred_username)[0];
      user = await fetchUserRoleFromId(lambdaUrl, fonciaId, user);

      if (
        user.roles[0] !== "ADMIN" &&
        user.roles[0] !== "MANAGER_LECTEUR_RESEAU"
      )
        history.push("/");
      else setHasAccess(true);
    };

    if (authState.isAuthenticated && lambdaUrl) {
      fetchUser();
    }
  }, [
    conf,
    error,
    loading,
    lambdaUrl,
    authState.isAuthenticated,
    oktaAuth,
    history,
  ]);

  const classes = useStyles();

  const handleShowUserRoles = async () => {
    setChosenTable("Rôles d'Utilisateur");
    setShowUserRole(true);
  };

  const handleShowLecteurs = () => {
    setChosenTable("Lecteurs Réseaux");
    setShowUserRole(false);
  };

  const isUserRoleModifiable = (userAuth) => {
    if (!userAuth || !userAuth.roles) {
      return false;
    }
    let res = userAuth.roles.filter((role) => {
      return UserRoleModifyRights.includes(role);
    });
    return res.length > 0;
  };

  const isLecteurModifiable = (userAuth) => {
    if (!userAuth || !userAuth.roles) {
      return false;
    }
    let res = userAuth.roles.filter((role) => {
      return LecteurModifyRights.includes(role);
    });
    return res.length > 0;
  };

  useEffect(() => {
    if (!loading && !error && conf) {
      setLambdaUrl(conf.LAMBDA_URL);
    }
    if (userAuth && userAuth.preferred_username) {
      setFonciaId(/fr(x|)[0-9]*/.exec(userAuth.preferred_username)[0]);
    }
  }, [conf, error, loading, lambdaUrl, fonciaId, userAuth]);

  if (!hasAccess) return null;

  return (
    <section>
      <Grid container spacing={2}>
        <Grid item xs={12} className={clsx(classes.title, classes.containers)}>
          <Typography variant="h4" align="center" gutterBottom>
            Administration
          </Typography>
          {loading ? (
            <LinearProgress variant="indeterminate" color="secondary" />
          ) : (
            <Divider />
          )}
        </Grid>
        {isUserRoleModifiable(userAuth) && (
          <Grid container item xs={6} justify={"center"}>
            <Button
              variant="contained"
              className={clsx(classes.buttonMain, classes.buttonDetails)}
              onClick={handleShowUserRoles}
            >
              Tableau des droits d'utilisateur
            </Button>
          </Grid>
        )}
        {isLecteurModifiable(userAuth) && (
          <Grid container item xs={6} justify={"center"}>
            <Button
              variant="contained"
              className={clsx(classes.buttonMain, classes.buttonDetails)}
              onClick={handleShowLecteurs}
            >
              Tableau des lecteurs réseaux
            </Button>
          </Grid>
        )}
        <Grid
          container
          item
          xs={12}
          className={clsx(classes.title, classes.containers)}
        >
          <Typography variant="h4" align="center" gutterBottom>
            {chosenTable}
          </Typography>
          {showUserRole ? (
            <ListUserRoles lambdaUrl={lambdaUrl} userAuth={userAuth} />
          ) : (
            <ListLecteurReseaux lambdaUrl={lambdaUrl} />
          )}
        </Grid>
      </Grid>
    </section>
  );
};

export default Administration;
