import React from 'react';
import { A, Image } from 'react-html-email';

const DEFAULT_SIZE = 16;

export default function Socials({ metadata, property }) {
  return (
    <div
      style={{ width: '100%', lineHeight: '0px', ...property?.socials?.style }}
    >
      {metadata.socials.map((social) => {
        const size = social.sizeRatio
          ? Number(social.sizeRatio) * DEFAULT_SIZE
          : DEFAULT_SIZE;
        return (
          <A key={social.url} href={social.url}>
            <Image
              src={social.logo}
              width={size}
              height={size}
              alt={social.type}
              style={{ display: 'inline' }}
            />
          </A>
        );
      })}
    </div>
  );
}
