import React, { useEffect, useState } from 'react';
import { Button, Divider, Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './style';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { useSnackbar } from 'notistack';
import {
  fetchBanners,
  filterCategories,
  replaceWhitespaceAndRemoveAccents,
} from './utils';
import AlterBannerDialog from '../AlterBannerDialog';
import PropTypes from 'prop-types';

const INIT_STATE = {
  name: '',
  category: '',
  redirect_url: '',
  img: '',
  imgPreview: null,
  imgBannerKey: null,
};

const SignatureAdmin = ({ lambdaUrl, fonciaId, isAdmin, allowedBrand }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const allowedCategories = filterCategories(isAdmin, allowedBrand);
  const [selectedCat, setSelectedCat] = useState(allowedCategories[0]?.id);
  const [selectedId, setSelectedId] = useState(null);
  const [key, setKey] = useState(null);
  // const [actualBanner, setActualBanners] = useState(institBanners);
  const [open, setOpen] = useState(false);
  const [bannerState, setBannerState] = useState(INIT_STATE);
  const [type, setType] = useState(null);
  const [imgPreview, setImgPreview] = useState(null);
  const [banners, setBanners] = useState(null);

  useEffect(() => {
    if (bannerState.imgFile)
      setImgPreview(URL.createObjectURL(bannerState.imgFile));
  }, [bannerState.imgFile]);

  useEffect(() => {
    if (lambdaUrl) fetchBanners(lambdaUrl, setBanners);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lambdaUrl]);

  const handleChange = (e) => {
    setSelectedCat(e.target.value);
  };

  const handleClick = (type, index) => {
    if (type === 'creation') {
      resetFormState();
    }
    setType(type);
    setOpen(true);
  };

  const handleCancel = () => {
    setBannerState(INIT_STATE);
  };

  const doFetch = async (endpoint, body) => {
    fetch(lambdaUrl + endpoint, {
      method: 'POST',
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((res) => {
        fetchBanners(lambdaUrl, setBanners);
        enqueueSnackbar(`Mise à jour effectuée`, {
          variant: 'success',
        });
      })
      .catch((err) => {
        enqueueSnackbar('Un problème est survenu', {
          variant: 'error',
        });
        console.log('ERROR FETCHED :', err);
      });
  };

  function encodeImageFileAsURL(element, bannerInfos, endpoint) {
    var file = element;
    var reader = new FileReader();
    reader.onloadend = function () {
      bannerInfos.imgName = replaceWhitespaceAndRemoveAccents(
        bannerInfos.imgFile.name
      );
      bannerInfos.imgFile = reader.result;
      console.log('bannerInfos', bannerInfos);
      fetch(lambdaUrl + endpoint, {
        method: 'POST',
        body: JSON.stringify({ bannerInfos: bannerInfos, key: key }),
      })
        .then((res) => res.json())
        .then((res) => {
          resetFormState();
          fetchBanners(lambdaUrl, setBanners);
          enqueueSnackbar(`Mise à jour effectuée`, {
            variant: 'success',
          });
        })
        .catch((err) => {
          console.log('ERROR FETCHED :', err);
          enqueueSnackbar('Un problème est survenu', {
            variant: 'error',
          });
        });
    };
    return reader.readAsDataURL(file);
  }

  const handleValidate = async (type, updateImg = false, bannerId) => {
    if (type === 'creation') {
      let bannerInfos = { ...bannerState, fonciaId: fonciaId };
      encodeImageFileAsURL(bannerInfos.imgFile, bannerInfos, 'create-banners');
    } else if (type === 'modify') {
      let bannerInfos = {
        ...bannerState,
        bannerId: bannerId,
        updateImg: updateImg,
      };
      console.log('bannerInfos', bannerInfos);
      if (updateImg) {
        encodeImageFileAsURL(
          bannerInfos.imgFile,
          bannerInfos,
          'modify-banners'
        );
      } else {
        bannerInfos.imgKey = bannerInfos.imgBannerKey;
        const body = { bannerInfos: bannerInfos };
        await doFetch('modify-banners', body);
      }
    } else if (type === 'delete') {
      const body = { bannerId: bannerId, key: key };
      await doFetch('delete-banners', body);
    }
  };

  const resetFormState = () => {
    setBannerState({ ...INIT_STATE });
    setImgPreview(null);
  };

  return (
    <Grid container spacing={2}>
      <AlterBannerDialog
        open={open}
        setOpen={setOpen}
        type={type}
        bannerState={bannerState}
        setBannerState={setBannerState}
        handleValidate={handleValidate}
        imgPreview={imgPreview}
        handleCancel={handleCancel}
        selectedId={selectedId}
        allowedCategories={allowedCategories}
      />
      <Grid item xs={12} className={clsx(classes.title, classes.containers)}>
        <Typography variant="h4" align="center" gutterBottom>
          Gestion des signatures
        </Typography>
        <Divider />
      </Grid>
      <Grid item xs={12} className={clsx(classes.containers)}>
        <FormControl className={classes.formControl}>
          <InputLabel id="simple-select-label">Catégorie</InputLabel>
          <Select
            labelId="simple-select-label"
            id="simple-select"
            value={selectedCat}
            onChange={handleChange}
          >
            {allowedCategories.map((cat) => (
              <MenuItem key={cat.id} value={cat.id}>
                {cat.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="outlined"
          color="primary"
          className={classes.addButton}
          onClick={() => handleClick('creation')}
          startIcon={<AddIcon />}
        >
          Ajouter un bandeau
        </Button>
        <Grid container>
          {/* <Grid item xs={4}> */}
          {/* {actualBanner.map(({ alt, src }, index) => ( */}
          {banners
            ? banners
                .filter((banner) => banner.category === selectedCat)
                .map(
                  (
                    {
                      name,
                      banner_img_url,
                      banner_img_key,
                      id,
                      id_creator,
                      date_creation,
                      category,
                      redirect_url,
                    },
                    index
                  ) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={4}
                        key={id}
                        className={classes.root}
                      >
                        <Card>
                          <CardHeader
                            title={name}
                            subheader={`Créé par ${id_creator} le ${new Date(
                              date_creation
                            ).toLocaleString('fr')}`}
                          />
                          <CardContent>
                            <img
                              width={'100%'}
                              alt={name}
                              src={banner_img_url}
                              title="Foncia bandeau de signature"
                            />
                          </CardContent>
                          <CardActions>
                            <Grid container spacing={0} justify="center">
                              <Grid item xs={5}>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={() => {
                                    setSelectedId(id);
                                    console.log('gonna setKey', banner_img_key);
                                    setKey(banner_img_key);
                                    setBannerState({
                                      name: name,
                                      category: category,
                                      redirect_url: redirect_url,
                                      img: '',
                                      // img: banner_img_url,
                                      imgPreview: banner_img_url,
                                      imgBannerKey: banner_img_key,
                                    });
                                    handleClick('modify', index);
                                  }}
                                  startIcon={<EditIcon />}
                                >
                                  Modifier
                                </Button>
                              </Grid>
                              <Grid item xs={5}>
                                <Button
                                  variant="outlined"
                                  color="secondary"
                                  onClick={() => {
                                    setSelectedId(id);
                                    setKey(banner_img_key);
                                    handleClick('delete', index);
                                  }}
                                  startIcon={<DeleteIcon />}
                                >
                                  Supprimer
                                </Button>
                              </Grid>
                            </Grid>
                          </CardActions>
                        </Card>
                      </Grid>
                    );
                  }
                  // ))}
                )
            : 'Chargement'}
          {/* </Grid> */}
        </Grid>
      </Grid>
    </Grid>
  );
};

SignatureAdmin.propTypes = {
  lambdaUrl: PropTypes.string.isRequired,
  fonciaId: PropTypes.string.isRequired,
};

export default SignatureAdmin;
