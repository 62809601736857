import React, { useState } from "react";
import {
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "react-router-dom";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { mobileLinks } from "./utils";

const useStyles = makeStyles((theme) =>
  createStyles({
    homeButton: {
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(1),
      fontSize: "15px",
      marginLeft: 20,
      color: theme.palette.white.main,
      textDecoration: "none",
    },
    drawerButton: {
      fontSize: "20px",
      color: "black",
      textDecoration: "none",
    },
    menuButton: {
      marginRight: theme.spacing(2),
      position: "fixed",
      left: "30px",
      top: "5px",
    },
    list: {
      width: 250,
    },
  })
);

const MobileMenu = ({ versionNumber }) => {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenDrawer(!openDrawer);
  };
  return (
    <>
      <IconButton
        edge="start"
        className={classes.menuButton}
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer(true)}
      >
        <MenuIcon />
      </IconButton>
      <Button>
        <Tooltip title={`Numéro de version : ${versionNumber}`}>
          <Link to="/" className={classes.homeButton}>
            Apple Helper
          </Link>
        </Tooltip>
      </Button>
      <SwipeableDrawer
        anchor="left"
        open={openDrawer}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <div
          className={classes.list}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            <ListItem button>
              <Link to="/" className={classes.drawerButton}>
                <ListItemText>Accueil</ListItemText>
              </Link>
            </ListItem>
          </List>
          <Divider />
          <List>
            {mobileLinks.map(({ url, name }) => (
              <Link
                key={url}
                to={url}
                className={classes.drawerButton}
                color="inherit"
              >
                <ListItem button>
                  <ListItemText>{name}</ListItemText>
                </ListItem>
              </Link>
            ))}
          </List>
        </div>
      </SwipeableDrawer>
    </>
  );
};

export default MobileMenu;
