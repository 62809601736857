import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DesktopMenu from './DesktopMenu';
import MobileMenu from './MobileMenu';
import { Button } from '@material-ui/core';
// import { useSnackbar } from "notistack";
import { useOktaAuth } from '@okta/okta-react';
import { fetchUserRoleFromId } from '../API';
import { useConfFetch } from '../../hooks/useConfFetch';
import PropTypes from 'prop-types';
const package_json = require('../../../package.json');

const versionNumber = package_json?.version || '?';
const useStyles = makeStyles(() =>
  createStyles({
    toolbarContainer: {
      flex: 1,
      display: 'flex',
      justifyContent: 'space-evenly',
    },
    button: {
      fontWeight: 'bold',
    },
  })
);

const TopMenu = ({ setUserAuth }) => {
  const { oktaAuth, authState } = useOktaAuth();
  const [conf, error, loading] = useConfFetch();
  const classes = useStyles();
  const theme = useTheme();
  const isWide = useMediaQuery(theme.breakpoints.up('md'));
  const [lambdaUrl, setLambdaUrl] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      let user = await oktaAuth.getUser();
      const fonciaId = /fr(x|)[0-9]*/.exec(user.preferred_username)[0];
      // console.log("here", user.preferred_username, fonciaId);
      user = await fetchUserRoleFromId(lambdaUrl, fonciaId, user);
      setUser(user);
      setUserAuth(user);
    };

    if (!loading && !error && conf) {
      setLambdaUrl(conf.LAMBDA_URL);
    }
    if (authState.isAuthenticated && lambdaUrl) {
      fetchUser().then(r => {
        return r;
      });
    }
  }, [
    conf,
    error,
    loading,
    lambdaUrl,
    authState.isAuthenticated,
    oktaAuth,
    setUserAuth,
  ]);
  // const handleSignOut = () => {
  // auth
  //   .signOut()
  //   .then(() => {
  //     enqueueSnackbar("Déconnecté avec succès.", {
  //       variant: "success",
  //     });
  //     localStorage.setItem("@isConnected", "false");
  //     localStorage.removeItem("@connected_email");
  //     localStorage.removeItem("@token");
  //     setIsConnected(false);
  //   })
  //   .catch(function (error) {
  //     console.log("Error deconnecting", error);
  //   });
  // };

  const SignButton = authState.isAuthenticated ? (
    <Button
      color="secondary"
      onClick={() => {
        oktaAuth.signOut();
      }}
      className={classes.button}
    >
      Déconnexion
    </Button>
  ) : (
    <Button
      color="secondary"
      onClick={() => {
        oktaAuth.signInWithRedirect();
      }}
      className={classes.button}
    >
      Connexion
    </Button>
  );

  // useEffect(() => {
  //   setIsConnected(
  //     localStorage.getItem("@isConnected") === "true" ? true : false
  //   );
  // }, []);

  return (
    <AppBar position="fixed">
      <Toolbar>
        <div className={classes.toolbarContainer}>
          {isWide ? (
            <DesktopMenu user={user} versionNumber={versionNumber} />
          ) : (
            <MobileMenu versionNumber={versionNumber} />
          )}
          {SignButton}
          {/* {isConnected && (
            <Button color="secondary" onClick={handleSignOut}>
              Déconnection
            </Button>
          )} */}
        </div>
      </Toolbar>
    </AppBar>
  );
};

TopMenu.propTypes = {
  setUserAuth: PropTypes.func.isRequired,
};

export default TopMenu;
