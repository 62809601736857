import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from "@material-ui/core/DialogContent";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";

const useStyles = makeStyles((theme) =>
	createStyles({
		buttonConfirm: theme.typography.mainButton,
		marginButton: {
			marginRight: "5px"
		},
		buttonCancel: theme.typography.secondaryButton
	})
);

export default function ConfirmationDialog(props) {

	const classes = useStyles();
	
	let confirmationFooter = <div>
		<Button className={clsx(classes.buttonConfirm, classes.marginButton)} variant="contained"  startIcon={<CheckIcon/>} onClick={props.onConfirm}>Oui</Button>
		<Button className={clsx(classes.buttonCancel, classes.marginButton)} variant="contained" startIcon={<CloseIcon/>} onClick={props.onCancel}>Non</Button>
	</div>;

	return (
		<Dialog open={props.visible} onClose={props.onCancel}>
			<DialogTitle className="Dialog-header">
				<Grid container spacing={2}>
					<Grid item xs={8}>
						<Typography variant="h5" align="left">
							Confirmation
						</Typography>
					</Grid>
				</Grid>
			</DialogTitle>
			<DialogContent className="Dialog-content" dividers>
				<Typography variant="h6" align="center" gutterBottom>
					{props.text}
				</Typography>
			</DialogContent>
			<DialogActions className="Dialog-actions">
				{confirmationFooter}
			</DialogActions>
		</Dialog>
	)
}