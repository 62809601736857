import React from 'react';
import PropTypes from 'prop-types';
import { Span, A, Box, Image as ImageMail } from 'react-html-email';
import Socials from '../component/Socials';
import Website from '../component/Website';
import { getLineHeight } from '../utils';
import Image from '../component/Image';

export default function TechWayTemplate({
  state,
  targetIsApp,
  property,
  metadata,
  isMobile,
  selectedBrand,
}) {
  return (
    <Box cellPadding={0} cellSpacing={0} style={{ borderCollapse: 'collapse' }}>
      <tr>
        <Image
          property={property}
          selectedBrand={selectedBrand}
          isMobile={isMobile}
        />
        <td valign="middle">
          <Box>
            <tr>
              <td
                style={{
                  paddingBottom: '5pt',
                }}
              >
                <div
                  id="name"
                  style={{
                    lineHeight: getLineHeight(targetIsApp, '20px'),
                  }}
                >
                  <Span
                    style={{
                      fontWeight: 'bold',
                      fontSize: 11,
                    }}
                  >
                    {state[0].value || 'Prénom'} {state[1].value || 'Nom'}
                  </Span>
                </div>
                <div
                  id="position"
                  style={{
                    lineHeight: getLineHeight(targetIsApp, '20px'),
                  }}
                >
                  <Span
                    style={{
                      fontSize: 11,
                    }}
                  >
                    {state[2].value || 'Fonction'}
                  </Span>
                </div>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderTop: `1px solid #f28d00`,
                }}
              >
                <div>
                  <Span>
                    <div style={{ lineHeight: '17px', fontSize: '17px' }}>
                      &nbsp;
                    </div>
                  </Span>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <Box>
                  {(state[5].value || state[6].value) && (
                    <tr>
                      <td width={25}>
                        <ImageMail
                          width={15}
                          height={15}
                          alt="Tel logo"
                          src={`${process.env.PUBLIC_URL}/static/logos/socials/tech-way/tel.png`}
                        />
                      </td>
                      <td>
                        <div
                          id="phone"
                          style={{
                            lineHeight: getLineHeight(targetIsApp, '20px'),
                          }}
                        >
                          <Span
                            fontSize={11}
                            style={{
                              color: '#000000',
                            }}
                          >
                            {state[5].value &&
                              state[5].value.replace(/\./g, ' ')}
                            {state[5].value && state[6].value && ' | '}
                            {state[6].value &&
                              state[6].value.replace(/\./g, ' ')}
                          </Span>
                        </div>
                      </td>
                    </tr>
                  )}

                  <tr>
                    <td width={25}>
                      <ImageMail
                        width={15}
                        height={15}
                        alt="Mail logo"
                        src={`${process.env.PUBLIC_URL}/static/logos/socials/tech-way/mail.png`}
                      />
                    </td>
                    <td>
                      <div
                        id="mail"
                        style={{
                          lineHeight: getLineHeight(targetIsApp, '20px'),
                        }}
                      >
                        <Span fontSize={11}>
                          <A
                            href={`mailto:${state[7].value}`}
                            textDecoration="none"
                            style={{
                              color: '#000000',
                              textDecoration: 'none',
                            }}
                          >
                            {state[7].value || 'Adresse mail'}
                          </A>
                        </Span>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td width={25}>
                      <ImageMail
                        width={15}
                        height={15}
                        alt="Location logo"
                        src={`${process.env.PUBLIC_URL}/static/logos/socials/tech-way/loc.png`}
                      />
                    </td>
                    <td>
                      <div
                        id="address"
                        style={{
                          lineHeight: getLineHeight(targetIsApp, '20px'),
                        }}
                      >
                        <Span
                          fontSize={11}
                          style={{
                            color: '#000000',
                          }}
                        >
                          {state[4].value || 'Adresse'}
                        </Span>
                      </div>
                    </td>
                  </tr>
                </Box>
              </td>
            </tr>

            {/* SOCIALS */}
            <Socials metadata={metadata} />

            {/* WEBSITE */}
            <Website targetIsApp={targetIsApp} property={property} />
          </Box>
        </td>
      </tr>
    </Box>
  );
}

TechWayTemplate.propTypes = {
  state: PropTypes.string.isRequired,
  targetIsApp: PropTypes.bool.isRequired,
  property: PropTypes.shape({}).isRequired,
  metadata: PropTypes.shape({}).isRequired,
  isMobile: PropTypes.bool.isRequired,
  selectedBrand: PropTypes.string.isRequired,
};
