import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";
import { OktaAuth } from "@okta/okta-auth-js";
import Home from "../Views/Home";
import SignIn from "../Views/SignIn";
import Repertoires from "../Views/Repertoires";
import Signatures from "../Views/Signatures";
import TopMenu from "../Components/TopMenu";
import Container from "@material-ui/core/Container";

import { useConfFetch } from "../hooks/useConfFetch";
import Administration from "../Views/Admin";
import { UpdateLecteur, UpdateUserRole } from "../Views/Admin/UpdateItems";

const AppWithRouterAccess = () => {
  const [authClient, setAuthClient] = useState(null);
  const [oktaIssuer, setOktaIssuer] = useState(null);
  const [userAuth, setUserAuth] = useState(null);
  // const { authState, oktaAuth } = useOktaAuth();

  const [conf, error, loading] = useConfFetch();

  useEffect(() => {
    if (!loading && !error && conf) {
      setOktaIssuer(conf.oktaIssuer);
      setAuthClient(
        new OktaAuth({
          issuer: conf.OKTA_URL,
          clientId: conf.OKTA_CLIENT_ID,
          redirectUri: window.location.origin + "/login/callback",
          scopes: ["openid", "profile"],
          pkce: true,
        })
      );
    }
  }, [conf, error, loading]);

  return (
    <Security oktaAuth={authClient} pkce={true}>
      <TopMenu setUserAuth={setUserAuth} />
      <Container
        maxWidth="lg"
        style={{
          marginTop: "73px",
        }}
      >
        <Route path="/" exact={true} component={Home} />
        <SecureRoute path="/repertoires" component={Repertoires} />
        {/* <Route path="/test" component={Signatures} /> */}
        <SecureRoute path="/signatures" component={Signatures} />
        <SecureRoute
          exact={true}
          path="/admin"
          render={() => <Administration userAuth={userAuth} />}
        />
        <SecureRoute
          exact={true}
          path="/admin/modifierLecteur/:id"
          component={UpdateLecteur}
        />
        <SecureRoute
          exact={true}
          path="/admin/modifierUserRole/:id"
          component={UpdateUserRole}
        />
        <Route path="/login" render={() => <SignIn issuer={oktaIssuer} />} />
        <Route path="/login/callback" component={LoginCallback} />
      </Container>
    </Security>
  );
};
export default AppWithRouterAccess;
