import React, { useEffect, useState } from 'react';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { initState } from './utils';
import BannerDialog from './BannerDialog';
import { useConfFetch } from '../../hooks/useConfFetch';
import { askInfo, fetchUserRoleFromId } from '../../Components/API';
import { useOktaAuth } from '@okta/okta-react';

import AppBar from '@material-ui/core/AppBar';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import Tab from '@material-ui/core/Tab';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import Signature from '../../Components/Signature';
import SignatureAdmin from '../../Components/SignatureAdmin';
import { brands } from '../../Components/Signature/assets/brands';

export const bannerDefaultValue = {
  cat: null,
  index: null,
  src: null,
  bannerLink: null,
};

const useStyles = makeStyles(theme => ({
  title: {
    margin: theme.spacing(1, 0, 0),
  },
  formContainer: {
    justifyContent: 'space-between',
  },
  containers: {
    backgroundColor: theme.palette.background.paper,
  },
  imgsContainer: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  logo: {
    cursor: 'pointer',
    padding: '6px',
    border: '1px solid transparent',
  },
  selectedLogo: {
    border: '1px solid #ed6905',
    borderRadius: '10px',
  },
  signatureContainer: {
    border: '1px solid #ee6c0e',
    // height: "180px", // IF NO BANNER
    height: '320px',
    padding: '10px',
    width: '100%',
    borderRadius: '10px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  copyButton: {
    marginTop: '22px',
  },
  tutoTitle: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.paper,
  },
}));

const Signatures = () => {
  const classes = useStyles();
  const [conf, error, loading] = useConfFetch();
  const { oktaAuth } = useOktaAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [isManager, setIsManager] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [allowedBrand, setAllowedBrand] = useState('');
  const [fonciaId, setFonciaId] = useState(null);
  const [activeTab, setActiveTab] = useState('signature');

  const [state, setState] = useState(initState);
  const [fetching, setFetching] = useState(false);
  const [open, setOpen] = useState(false);

  const [selectedBanner, setSelectedBanner] = useState({
    ...bannerDefaultValue,
  });
  const [showUseGroup, setShowUseGroup] = useState(true);
  const [useBanner, setUseBanner] = useState(false);

  const [lambdaUrl, setLambdaUrl] = useState(null);

  const [selectedBrand, setSelectedBrand] = useState(brands[0]);

  useEffect(() => {
    if (!loading && !error && conf) {
      setLambdaUrl(conf.LAMBDA_URL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conf, error, loading]);

  useEffect(() => {
    if (lambdaUrl === null) return;
    // Retrieve user id
    async function getId() {
      try {
        const user = await oktaAuth.getUser();
        const id = /fr(x|)[0-9]*/.exec(user.preferred_username)[0];
        setFonciaId(id);

        // Handle admin access to banner management
        const userDetails = await fetchUserRoleFromId(lambdaUrl, id, user);
        if (['ADMIN', 'MANAGER_SIGNATURE'].includes(userDetails.role)) {
          setIsManager(true);
          setAllowedBrand(userDetails.brand);
          setIsAdmin(false);
          if (['ADMIN'].includes(userDetails.role)) {
            setIsAdmin(true);
          }
        } else {
          setIsManager(false);
          setIsAdmin(false);
          setAllowedBrand('');
        }
      } catch (err) {
        console.log(err);
      }
    }
    // Fetch info on load
    async function fetchData() {
      try {
        let user = await oktaAuth.getUser();
        user = user.preferred_username;
        const fr = user.substr(0, user.indexOf('@'));

        askInfo(fr, lambdaUrl, state, setState);
        setFetching(false);
      } catch (err) {
        setFetching(false);
        console.log(err);
      }
    }

    getId();
    const storedSignature = JSON.parse(localStorage.getItem('signature'));
    const lastFetch = new Date(
      Date.parse(localStorage.getItem('lastSignatureFetch'))
    );
    if (storedSignature !== null && lastFetch !== null) {
      setState(storedSignature);
      // After 10 days, refetch from the APIM in background
      const tenDays = new Date(new Date().getTime() + 10 * 24 * 60 * 60 * 1000);
      if (tenDays < lastFetch) {
        setFetching(true);
        fetchData();
      }
    } else {
      setFetching(true);
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lambdaUrl]);

  useEffect(() => {
    if (selectedBanner.cat !== null && selectedBanner.index !== null) {
      // Close banner popup after selection
      setUseBanner(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBanner.cat, selectedBanner.index]);

  const handleChange = (e, index) => {
    let newState = [...state];
    newState[index].value = e.target.value;
    setState(newState);
  };

  const addBandeau = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <section>
      {isManager ? (
        <TabContext value={activeTab}>
          <AppBar
            position="static"
            variant="elevation"
            color="transparent"
            className={classes.appbar}
          >
            <TabList
              value={activeTab}
              onChange={handleTabChange}
              indicatorColor="secondary"
              textColor="secondary"
              aria-label="Planning tabs"
              centered
            >
              <Tab
                label="Signature"
                value="signature"
                icon={<PersonPinIcon />}
                wrapped
              />
              <Tab
                label="Admin"
                value="admin"
                icon={<GroupAddIcon />}
                wrapped
              />
            </TabList>
          </AppBar>
          <TabPanel value="signature" className={classes.tabpanel}>
            <Signature
              fetching={fetching}
              state={state}
              handleChange={handleChange}
              setShowUseGroup={setShowUseGroup}
              setUseBanner={setUseBanner}
              showUseGroup={showUseGroup}
              addBandeau={addBandeau}
              useBanner={useBanner}
              selectedBanner={selectedBanner}
              setSelectedBanner={setSelectedBanner}
              isMobile={isMobile}
              selectedBrand={selectedBrand}
              setSelectedBrand={setSelectedBrand}
            />
          </TabPanel>
          <TabPanel value="admin" className={classes.tabpanel}>
            {lambdaUrl && fonciaId && (
              <SignatureAdmin
                fonciaId={fonciaId}
                lambdaUrl={lambdaUrl}
                allowedBrand={allowedBrand}
                isAdmin={isAdmin}
              />
            )}
          </TabPanel>
        </TabContext>
      ) : (
        <Signature
          fetching={fetching}
          state={state}
          handleChange={handleChange}
          setShowUseGroup={setShowUseGroup}
          setUseBanner={setUseBanner}
          showUseGroup={showUseGroup}
          addBandeau={addBandeau}
          useBanner={useBanner}
          selectedBanner={selectedBanner}
          setSelectedBanner={setSelectedBanner}
          isMobile={isMobile}
          selectedBrand={selectedBrand}
          setSelectedBrand={setSelectedBrand}
        />
      )}

      {/* Dialog Form */}
      <BannerDialog
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        selectedBanner={selectedBanner}
        setSelectedBanner={setSelectedBanner}
        lambdaUrl={lambdaUrl}
        selectedBrand={selectedBrand}
      />
    </section>
  );
};

export default Signatures;
