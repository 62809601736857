import React from 'react';
import PropTypes from 'prop-types';
import { Item, Span, A, Box, Image as ImageMail } from 'react-html-email';
import EmailFixed from './EmailFixed';
import GenericTemplate from './template/GenericTemplate';
import AssurimoTemplate from './template/AssurimoTemplate';
import StaresTemplate from './template/Stares';
import TechWayTemplate from './template/Techway';
import Image from './component/Image';
import { BRANDS_ENUM } from '../../Components/Signature/assets/brands';
import Website from './component/Website';
import Socials from './component/Socials';
import ImmoCorseTemplate from './template/ImmoCorseTemplate';
import VacancesFonciaTemplate from './template/VacancesFonciaTemplate';

function GeneratedEmail({
  state,
  selectedBrand,
  selectedBanner,
  useBanner,
  isMobile,
  targetIsApp,
}) {
  const { metadata } = selectedBrand;
  const { property } = metadata;
  const getTemplate = () => {
    switch (metadata.customTemplate) {
      case BRANDS_ENUM.ASSURIMO:
        return (
          <AssurimoTemplate
            state={state}
            targetIsApp={targetIsApp}
            property={property}
            metadata={metadata}
            isMobile={isMobile}
            selectedBrand={selectedBrand}
          />
        );
      case BRANDS_ENUM.TECH_WAY:
        return (
          <TechWayTemplate
            state={state}
            targetIsApp={targetIsApp}
            property={property}
            metadata={metadata}
            isMobile={isMobile}
            selectedBrand={selectedBrand}
          />
        );
      case BRANDS_ENUM.STARES:
        return (
          <StaresTemplate
            state={state}
            targetIsApp={targetIsApp}
            property={property}
            metadata={metadata}
            isMobile={isMobile}
            selectedBrand={selectedBrand}
          />
        );
      case BRANDS_ENUM.ESSET_PROPERTY:
        // eslint-disable-next-line no-case-declarations
        const footer = (
          <>
            {/* WEBSITE */}
            <Website targetIsApp={targetIsApp} property={property} />
            {/* SOCIALS */}
            <Socials metadata={metadata} property={property} />
          </>
        );
        return (
          <Box
            cellPadding={0}
            cellSpacing={0}
            style={{ borderCollapse: 'collapse' }}
          >
            <tr>
              <Image
                property={property}
                selectedBrand={selectedBrand}
                isMobile={isMobile}
              />
              <GenericTemplate
                state={state}
                targetIsApp={targetIsApp}
                property={property}
                metadata={metadata}
                isMobile={isMobile}
                footer={footer}
                phoneSeparator="&nbsp;&nbsp;-&nbsp;&nbsp;"
                shouldBreakAddress
              />
            </tr>
          </Box>
        );
      case BRANDS_ENUM.IMMO_CORSE:
        return (
          <ImmoCorseTemplate
            state={state}
            targetIsApp={targetIsApp}
            property={property}
            metadata={metadata}
            isMobile={isMobile}
            selectedBrand={selectedBrand}
          />
        );
      case BRANDS_ENUM.FONCIA_VACANCES:
        return (
          <Box
            cellPadding={0}
            cellSpacing={0}
            style={{ borderCollapse: 'collapse' }}
          >
            <tr>
              <Image
                property={property}
                selectedBrand={selectedBrand}
                isMobile={isMobile}
              />
              <VacancesFonciaTemplate
                state={state}
                targetIsApp={targetIsApp}
                property={property}
                metadata={metadata}
                isMobile={isMobile}
              />
            </tr>
          </Box>
        );
      default:
        return (
          <Box
            cellPadding={0}
            cellSpacing={0}
            style={{ borderCollapse: 'collapse' }}
          >
            <tr>
              <Image
                property={property}
                selectedBrand={selectedBrand}
                isMobile={isMobile}
              />
              <GenericTemplate
                state={state}
                targetIsApp={targetIsApp}
                property={property}
                metadata={metadata}
                isMobile={isMobile}
              />
            </tr>
          </Box>
        );
    }
  };

  return (
    <pre style={{ marginLeft: '0px !important' }}>
      <EmailFixed title="Signature Emeria" align="left">
        <Box
          cellPadding={0}
          cellSpacing={0}
          width="100%"
          style={{ borderCollapse: 'collapse' }}
        >
          <tr>
            <td align="start">{getTemplate()}</td>
          </tr>
        </Box>
        {useBanner && selectedBanner.src !== null && (
          <Item>
            <Span>
              <br />
              <A href={selectedBanner.bannerLink} style={{ cursor: 'pointer' }}>
                <ImageMail
                  src={selectedBanner.src !== null && selectedBanner.src}
                  alt="Bannière Foncia"
                  width={500}
                  style={{ width: '100%', marginTop: '5px' }}
                />
              </A>
            </Span>
          </Item>
        )}
      </EmailFixed>
    </pre>
  );
}

GeneratedEmail.propTypes = {
  state: PropTypes.object.isRequired,
  selectedBrand: PropTypes.string.isRequired,
  selectedBanner: PropTypes.object.isRequired,
  useBanner: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  targetIsApp: PropTypes.bool.isRequired,
};

export default GeneratedEmail;
