import React from 'react';
import PropTypes from 'prop-types';
import { Span, A, Box } from 'react-html-email';
import Socials from '../component/Socials';
import Website from '../component/Website';
import { breakAdressFormat, getLineHeight } from '../utils';
import Image from '../component/Image';

export default function StaresTemplate({
  state,
  targetIsApp,
  property,
  metadata,
  isMobile,
  selectedBrand,
}) {
  return (
    <Box cellPadding={0} cellSpacing={0} style={{ borderCollapse: 'collapse' }}>
      <tr>
        <Box
          cellPadding={0}
          cellSpacing={0}
          style={{ borderCollapse: 'collapse' }}
        >
          <tr>
            <td
              style={{
                paddingBottom: '15pt',
              }}
            >
              <div
                id="name"
                style={{
                  lineHeight: getLineHeight(targetIsApp),
                }}
              >
                <Span
                  style={{
                    fontSize: '9.0pt',
                    fontFamily: 'Verdana',
                    color: '#192735',
                  }}
                >
                  {state[0].value || 'Prénom'} {state[1].value || 'Nom'}
                </Span>
              </div>
              <div
                id="position"
                style={{ lineHeight: getLineHeight(targetIsApp) }}
              >
                <Span
                  style={{
                    fontSize: '9.0pt',
                    fontFamily: 'Verdana',
                  }}
                >
                  {state[2].value || 'Fonction'}
                </Span>
              </div>
            </td>
          </tr>
        </Box>
      </tr>
      <tr>
        <Box
          cellPadding={0}
          cellSpacing={0}
          style={{ borderCollapse: 'collapse' }}
        >
          <Image
            property={property}
            selectedBrand={selectedBrand}
            isMobile={isMobile}
          />
          <td
            style={{
              verticalAlign: 'middle',
              paddingLeft: '15pt',
            }}
          >
            <div>
              <div>
                <Span
                  style={{
                    fontSize: '8.5pt',
                    fontFamily: 'Montserrat',
                    color: '#192735',
                    fontWeight: 'bold',
                  }}
                >
                  STARES Gestion Locative
                </Span>
              </div>
              <div
                id="address"
                style={{ lineHeight: getLineHeight(targetIsApp) }}
              >
                <Span
                  fontSize={11}
                  style={{
                    fontSize: '8.5pt',
                    fontFamily: 'Verdana',
                    color: '#192735',
                  }}
                >
                  {state[4].value
                    ? breakAdressFormat(state[4].value, true)
                    : 'Adresse'}
                </Span>
              </div>
              {(state[5].value || state[6].value) && (
                <div
                  id="phone"
                  style={{ lineHeight: getLineHeight(targetIsApp) }}
                >
                  <Span
                    fontSize={11}
                    style={{
                      fontSize: '8.5pt',
                      fontFamily: 'Verdana',
                      color: '#192735',
                    }}
                  >
                    {state[5].value && state[5].value.replace(/\./g, ' ')}
                    {state[5].value && state[6].value && (
                      <br style={{ msoDataPlacement: 'same-cell' }} />
                    )}
                    {state[6].value && state[6].value.replace(/\./g, ' ')}
                  </Span>
                </div>
              )}
              <div id="mail" style={{ lineHeight: getLineHeight(targetIsApp) }}>
                <Span fontSize={11}>
                  <A
                    href={`mailto:${state[7].value}`}
                    style={{
                      fontSize: '8.5pt',
                      fontFamily: 'Verdana',
                      textDecoration: 'none',
                      color: '#2DB7BC',
                    }}
                  >
                    {state[7].value || 'Adresse mail'}
                  </A>
                </Span>
              </div>
              <br style={{ msoDataPlacement: 'same-cell' }} />
              {/* WEBSITE */}
              <Website targetIsApp={targetIsApp} property={property} />
            </div>
          </td>
        </Box>
      </tr>
      <tr>
        <td
          style={{
            paddingTop: '10pt',
          }}
        >
          {/* SOCIALS */}
          <Socials metadata={metadata} />
        </td>
      </tr>
    </Box>
  );
}

StaresTemplate.propTypes = {
  state: PropTypes.string.isRequired,
  targetIsApp: PropTypes.bool.isRequired,
  property: PropTypes.shape({}).isRequired,
  metadata: PropTypes.shape({}).isRequired,
  isMobile: PropTypes.bool.isRequired,
  selectedBrand: PropTypes.string.isRequired,
};
