import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { useState } from 'react';

const AlterBannerDialog = ({
  open,
  setOpen,
  type,
  bannerState,
  setBannerState,
  handleValidate,
  imgPreview,
  handleCancel,
  selectedId,
  allowedCategories,
}) => {
  const [updateImg, setUpdateImg] = useState(false);
  const handleChange = (e, altId) => {
    let newState = { ...bannerState };
    newState[e.target.id || altId] = e.target.value;
    if (e.target.id === 'img') {
      setUpdateImg(true);
      // console.log("e", e);
      // debugger;
      newState.imgFile = e.target.files[0];
    }
    setBannerState(newState);
  };

  console.log(bannerState);

  useState(() => {
    setUpdateImg(false);
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{`${
        type === 'modify'
          ? 'Modification'
          : type === 'creation'
          ? 'Création'
          : 'Suppression'
      } du bandeau`}</DialogTitle>
      <DialogContent>
        {type === 'delete' ? (
          <DialogContentText>
            Vous vous apprêtez à supprimer cette bannière, êtes vous sûr de vous
            ?
          </DialogContentText>
        ) : (
          <>
            <TextField
              autoFocus
              margin="dense"
              label="Nom du bandeau"
              type="text"
              id="name"
              value={bannerState.name}
              onChange={e => handleChange(e)}
              fullWidth
            />
            <FormControl fullWidth>
              <InputLabel id="simple-select-label">Catégorie</InputLabel>
              <Select
                labelId="simple-select-label"
                value={bannerState.category}
                fullWidth
                onChange={e => handleChange(e, 'category')}
              >
                {allowedCategories.map(cat => (
                  <MenuItem key={cat.id} value={cat.id}>
                    {cat.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              autoFocus
              margin="dense"
              label="URL de redirection au clic sur le bandeau"
              type="text"
              id="redirect_url"
              value={bannerState.redirect_url}
              onChange={e => handleChange(e)}
              fullWidth
            />
            <TextField
              autoFocus
              margin="dense"
              label="Image du bandeau"
              type="file"
              id="img"
              value={bannerState.img}
              onChange={e => handleChange(e)}
              fullWidth
            />
            {(imgPreview || bannerState.imgPreview) && (
              <img
                src={imgPreview || bannerState.imgPreview}
                alt="Nouvelle bannière"
                style={{ width: '100%', marginTop: 20 }}
              />
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
            handleCancel();
          }}
          color="primary"
        >
          Annuler
        </Button>
        <Button
          onClick={() => {
            handleValidate(type, updateImg, selectedId);
            setOpen(false);
          }}
          color="primary"
        >
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AlterBannerDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  type: PropTypes.string,
  bannerState: PropTypes.shape({
    name: PropTypes.string,
    category: PropTypes.string,
    redirect_url: PropTypes.string,
    img: PropTypes.string,
    imgFile: PropTypes.object,
  }).isRequired,
  setBannerState: PropTypes.func.isRequired,
  handleValidate: PropTypes.func.isRequired,
};

export default AlterBannerDialog;
