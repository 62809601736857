import { createMuiTheme } from "@material-ui/core/styles";

const palette = {
  primary: {
    main: "#0c2241",
    light: "#0D63BF",
    dark: "#05284C",
  },
  secondary: {
    main: "#ed6a00",
    light: "#FAB49E",
    dark: "#f7866429",
  },
  gray: {
    main: "#c2c7d0",
    light: "#e9ecef",
    superLight: "#f4f6f9",
    dark: "#616161",
  },
  black: {
    main: "#000000",
    light: "#212529",
  },
  white: {
    main: "#ffffff",
  },
  green: {
    main: "#28a745",
  },
  yellow: {
    main: "#ffc107",
    light: "#fbee91",
  },
  red: {
    main: "#dc3545",
    light: "#fba5a1",
  },
  blue: {
    main: "#1a7de2",
    dark: "#343a40",
  },
  orange: {
    main: "#dc3545",
  },
  background: {
    default: "#e9eaed",
  },
};

const typography = {
  h5: {
    fontWeight: 700,
    color: palette.gray.dark,
  },
  h6: {
    fontSize: 17,
    fontWeight: 800,
    color: palette.gray.dark,
    // fontSize: 12,
    // fontWeight: 500,
  },
  subtitle1: {
    fontSize: 12,
    fontWeight: 700,
    color: palette.gray.dark,
  },
  body1: {
    fontSize: 13,
    color: palette.primary.main,
    fontWeight: 600,
  },
  body2: {
    fontSize: 12,
    color: palette.gray.dark,
  },
  subtitle2: {
    fontSize: 10,
    color: palette.red.main,
  },
  mainButton: {
    backgroundColor: palette.primary.dark,
    color: palette.white.main,
    "&:hover": {
      background: palette.primary.light,
    },
  },
  secondaryButton: {
    backgroundColor: palette.secondary.main,
    color: palette.white.main,
    "&:hover": {
      background: palette.secondary.light,
    },
  },
};

const theme = createMuiTheme({
  palette,
  typography,
  // overrides,
});

export default theme;
