import React from "react";
import PropTypes from "prop-types";
import { Divider, Grid, ListItem, ListItemAvatar } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
const Loader = ({ divider }) => (
  <div>
    <ListItem>
      <ListItemAvatar>
        <Skeleton animation="pulse" variant="circle" width={40} height={40} />
      </ListItemAvatar>
      <Grid container>
        <Grid item xs={5}>
          <Skeleton animation="wave" variant="text" width="100%" />
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={4}>
          <Skeleton animation="wave" variant="text" width="100%" />
        </Grid>
      </Grid>
    </ListItem>
    {divider && <Divider data-testid="divider" />}
  </div>
);

Loader.propTypes = {
  divider: PropTypes.bool.isRequired,
};

export default Loader;
