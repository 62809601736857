import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { explicationsList } from "../Signature/utils";
// import { explicationsList } from "../../Views/Signatures/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(13),
    fontWeight: theme.typography.fontWeightRegular,
  },
  listItem: {
    paddingTop: 0,
  },
}));

const Explications = () => {
  const classes = useStyles();

  return (
    <>
      {explicationsList.map((explication) => (
        <Accordion key={explication.title}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{explication.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List aria-label={explication.label}>
              {explication.items.map((item, index) => (
                <ListItem key={item} className={classes.listItem}>
                  <ListItemIcon>{index + 1}</ListItemIcon>
                  <ListItemText
                    primary={item}
                    className={classes.heading}
                    disableTypography
                  />
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default Explications;
