import { BRANDS_ENUM } from '../Signature/assets/brands';
import categories from './Assets/category';

async function fetchBanners(lambdaUrl, setBanners) {
  return fetch(lambdaUrl + 'list-banners', {
    method: 'POST',
  })
    .then((res) => res.json())
    .then((res) => {
      // Sort banners by created date, otherse by id if it's the same date
      const sorted = res.banners.sort((a, b) => {
        if (
          new Date(a.date_creation).getTime() ===
          new Date(b.date_creation).getTime()
        ) {
          return b.id - a.id;
        }
        return new Date(b.date_creation) - new Date(a.date_creation);
      });
      setBanners(sorted);
    })
    .catch((err) => {
      console.log('ERROR FETCHED :', err);
    });
}

/**
 * Filter categories by user role and allowed brand
 * @returns Filtered categories
 */
const filterCategories = (isAdmin, allowedBrand) => {
  if (isAdmin) {
    return categories;
  }
  return categories.filter((cat) =>
    cat.brandsAllowed.includes(BRANDS_ENUM[allowedBrand])
  );
};

/**
 * Replace whitespace and remove accent
 * @param {string} input
 * @returns {string}
 */
const replaceWhitespaceAndRemoveAccents = (input) => {
  // Remove accents
  const withoutAccents = input.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  // Replace whitespace with hyphens
  const withHyphens = withoutAccents.replace(/\s+/g, '-');

  return withHyphens;
};

export {
  categories,
  fetchBanners,
  filterCategories,
  replaceWhitespaceAndRemoveAccents,
};
