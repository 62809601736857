import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  LinearProgress,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { renderEmail } from 'react-html-email';
import useStyles from './style';
import GeneratedEmail from '../../Views/Signatures/GeneratedEmail';
import { brands } from './assets/brands';
import Explications from '../Explications';
import { bannerDefaultValue } from '../../Views/Signatures';
import { Alert } from '@material-ui/lab';

function Signature({
  fetching,
  state,
  handleChange,
  setShowUseGroup,
  setUseBanner,
  showUseGroup,
  addBandeau,
  useBanner,
  selectedBanner,
  setSelectedBanner,
  isMobile,
  selectedBrand,
  setSelectedBrand,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleBrandChange = brand => {
    setShowUseGroup(brand.canChooseBanner);
    setSelectedBrand(brand);
    if (selectedBanner.src) {
      setSelectedBanner({ ...bannerDefaultValue });
    }
  };

  const [targetIsApp, setTargetIsApp] = useState(true);
  const emailHTML = renderEmail(
    <GeneratedEmail
      state={state}
      selectedBrand={selectedBrand}
      isMobile={/iPad/.test(navigator.userAgent) ? false : isMobile}
      selectedBanner={selectedBanner}
      useBanner={useBanner}
      targetIsApp={/iPad/.test(navigator.userAgent) ? false : targetIsApp}
    />
  );
  const copyToClipboard = async () => {
    if (navigator.userAgent.match(/ipad|ipod|iphone|safari/i)) {
      // Create and hide container
      const container = document.createElement('div');
      container.innerHTML = emailHTML;
      container.style.position = 'fixed';
      container.style.pointerEvents = 'none';
      container.style.opacity = 0;
      // Mount the container to the DOM to make `contentWindow` available
      document.body.appendChild(container);
      // Copy to clipboard
      window.getSelection().removeAllRanges();
      const range = document.createRange();
      range.selectNode(container);
      window.getSelection().addRange(range);
      document.execCommand('copy');
      // Remove the container
      document.body.removeChild(container);
    } else {
      function listener(e) {
        e.clipboardData.setData('text/html', emailHTML);
        // e.clipboardData.setData("text/plain", emailHTML);
        e.preventDefault();
      }
      document.addEventListener('copy', listener);
      document.execCommand('copy');
      document.removeEventListener('copy', listener);
    }

    enqueueSnackbar(
      'Signature copiée dans votre presse papier, vous pouvez la coller dans votre client mail.',
      {
        variant: 'success',
      }
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className={clsx(classes.title, classes.containers)}>
        <Typography variant="h4" align="center" gutterBottom>
          Mes signatures
          {!isMobile && (
            <FormControlLabel
              style={{ paddingLeft: 40 }}
              control={
                <Switch
                  checked={targetIsApp}
                  onChange={e => setTargetIsApp(e.target.checked)}
                />
              }
              label={
                targetIsApp
                  ? '(Signature optimisée pour Outlook Desktop)'
                  : '(Signature optimisée pour Outlook WebApp)'
              }
            />
          )}
        </Typography>
        {fetching ? (
          <LinearProgress variant="indeterminate" color="secondary" />
        ) : (
          <Divider />
        )}
      </Grid>
      <Grid item xs={12} md={6} className={clsx(classes.containers)}>
        <form>
          <Grid container className={classes.formContainer}>
            {state.map((el, i) => (
              <Grid item sm={el.wide ? 12 : 5} xs={12} key={el.id}>
                <TextField
                  id={el.id}
                  label={el.label}
                  variant="outlined"
                  fullWidth
                  size="small"
                  margin="dense"
                  value={el.value}
                  onChange={e => handleChange(e, i)}
                />
              </Grid>
            ))}
          </Grid>
          <Grid
            container
            className={classes.imgsContainer}
            alignItems="center"
            justify="space-between"
          >
            <Grid item xs={12} md={12}>
              {brands.map(brand => (
                <img
                  key={brand.src}
                  src={brand.miniature ?? brand.src}
                  alt={brand.alt}
                  className={clsx(
                    classes.logo,
                    selectedBrand.src === brand.src && classes.selectedLogo
                  )}
                  onClick={() => {
                    handleBrandChange(brand);
                  }}
                />
              ))}
            </Grid>

            {showUseGroup && (
              <Grid item xs={12} md={12}>
                <div>
                  <Button
                    style={{
                      marginRight: '5px',
                    }}
                    color="secondary"
                    variant="outlined"
                    onClick={addBandeau}
                  >
                    Choisir un bandeau de signature
                  </Button>
                  <Tooltip title="Afficher le bandeau de signature ?">
                    <Checkbox
                      checked={useBanner}
                      onChange={() => setUseBanner(!useBanner)}
                      inputProps={{
                        'aria-label': 'Afficher le bandeau de signature',
                      }}
                    />
                  </Tooltip>
                </div>
                {useBanner && !selectedBanner.src && (
                  <Alert
                    style={{
                      marginTop: '10px',
                    }}
                    severity="warning"
                  >
                    Aucun bandeau n&apos;a été choisi
                  </Alert>
                )}
              </Grid>
            )}
          </Grid>
        </form>
      </Grid>
      <Grid item xs={12} md={6} className={clsx(classes.containers)}>
        <div
          className={classes.signatureContainer}
          dangerouslySetInnerHTML={{ __html: emailHTML }}
          // style={{
          //   justifyContent:
          //     useBanner && selectedBanner.cat !== null
          //       ? "flex-start"
          //       : "center",
          // }}
        />
        {/* {emailHTML} */}
        <Button
          color="secondary"
          variant="outlined"
          onClick={copyToClipboard}
          fullWidth
          className={classes.copyButton}
        >
          Copier la signature
        </Button>
      </Grid>
      <Grid item xs={12} className={classes.containers}>
        <Typography
          className={classes.tutoTitle}
          color="secondary"
          variant="h5"
        >
          Comment utiliser votre nouvelle signature ?
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.containers}>
        <Explications />
      </Grid>
    </Grid>
  );
}

Signature.propTypes = {
  fetching: PropTypes.bool.isRequired,
  state: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      wide: PropTypes.bool.isRequired,
    })
  ).isRequired,
  handleChange: PropTypes.func.isRequired,
  setShowUseGroup: PropTypes.func.isRequired,
  setUseBanner: PropTypes.func.isRequired,
  showUseGroup: PropTypes.bool.isRequired,
  addBandeau: PropTypes.func.isRequired,
  useBanner: PropTypes.bool.isRequired,
  selectedBanner: PropTypes.shape({
    cat: PropTypes.string,
    index: PropTypes.number,
    bannerLink: PropTypes.string,
  }).isRequired,
};

export default Signature;
