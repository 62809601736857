import React from 'react';
import PropTypes from 'prop-types';
import { Span, A, Box } from 'react-html-email';
import Socials from '../component/Socials';
import Website from '../component/Website';
import { breakAdressFormat, getCityFormAddress, getLineHeight } from '../utils';
import Image from '../component/Image';

const generateFooter = (metadata, targetIsApp, property) => (
  <>
    {/* SOCIALS */}
    <Socials metadata={metadata} property={property} />
    {/* WEBSITE */}
    <Website targetIsApp={targetIsApp} property={property} />
  </>
);

export default function ImmoCorseTemplate({
  state,
  targetIsApp,
  property,
  metadata,
  isMobile,
  selectedBrand,
}) {
  // eslint-disable-next-line no-case-declarations
  const tel = state[5].value;
  // eslint-disable-next-line no-case-declarations
  const portable = state[6].value;
  // eslint-disable-next-line no-case-declarations
  return (
    <Box cellPadding={0} cellSpacing={0} style={{ borderCollapse: 'collapse' }}>
      <tr>
        <Image
          property={property}
          selectedBrand={selectedBrand}
          isMobile={isMobile}
        />
        <td
          style={{
            paddingBottom:
              // eslint-disable-next-line no-nested-ternary
              isMobile ? '3.7px' : targetIsApp ? '0px' : '-8px',
          }}
        >
          <div>
            <div
              id="name"
              style={{
                lineHeight: getLineHeight(
                  targetIsApp,
                  property?.name?.style?.lineHeight
                ),
              }}
            >
              <Span
                style={{
                  color: '#000000',
                  fontFamily: 'Arial',
                  fontWeight: 'bold',
                  fontSize: '10pt',
                }}
              >
                {state[0].value || 'Prénom'} {state[1].value || 'Nom'}
              </Span>
            </div>
            <div
              id="position"
              style={{
                lineHeight: getLineHeight(
                  targetIsApp,
                  property?.position?.style?.lineHeight
                ),
              }}
            >
              <Span
                style={{
                  color: '#da6567',
                  fontFamily: 'Arial',
                  fontSize: '8pt',
                  textTransform: 'uppercase',
                }}
              >
                {state[2].value || 'Fonction'}
              </Span>
            </div>
            {state[3].value && (
              <div
                id="agency"
                style={{
                  lineHeight: getLineHeight(
                    targetIsApp,
                    property?.agency?.style?.lineHeight
                  ),
                }}
              >
                <Span
                  fontSize={11}
                  style={{
                    color: '#da6567',
                    fontFamily: 'Arial',
                    fontSize: '8pt',
                    fontWeight: 'bold',
                  }}
                >
                  {state[3].value || 'Agence ou Service'}
                </Span>
              </div>
            )}
            <div>
              <Span
                fontSize={11}
                style={{
                  color: '#da6567',
                  fontFamily: 'Arial',
                  fontSize: '8pt',
                }}
              >
                {getCityFormAddress(state[4].value)}
              </Span>
            </div>
            <div
              id="address"
              style={{
                lineHeight: getLineHeight(
                  targetIsApp,
                  property?.body?.style?.lineHeight
                ),
              }}
            >
              <Span
                fontSize={11}
                style={{
                  color: '#808080',
                  fontFamily: 'Arial',
                  fontSize: '8pt',
                }}
              >
                {state[4].value
                  ? breakAdressFormat(state[4].value, true)
                  : 'Adresse'}
              </Span>
            </div>
            {(state[5].value || state[6].value) && (
              <div
                id="phone"
                style={{
                  lineHeight: getLineHeight(
                    targetIsApp,
                    property?.body?.style?.lineHeight
                  ),
                }}
              >
                <Span
                  fontSize={11}
                  style={{
                    color: '#808080',
                    fontFamily: 'Arial',
                    fontSize: '8pt',
                  }}
                >
                  {tel && <>Tel : {tel.replace(/\./g, ' ')}</>}
                  {tel && portable && (
                    <br style={{ msoDataPlacement: 'same-cell' }} />
                  )}
                  {portable && <>Port : {portable.replace(/\./g, ' ')}</>}
                </Span>
              </div>
            )}
            <div
              id="mail"
              style={{
                lineHeight: getLineHeight(
                  targetIsApp,
                  property?.body?.style?.lineHeight
                ),
              }}
            >
              <Span fontSize={11}>
                <A
                  href={`mailto:${state[7].value}`}
                  textDecoration="none"
                  style={{
                    color: '#da6567',
                    fontWeight: 'bold',
                    fontFamily: 'Arial',
                    fontSize: '8pt',
                    textDecoration: 'none',
                  }}
                >
                  {state[7].value || 'Adresse mail'}
                </A>
              </Span>
            </div>
            {generateFooter(metadata, targetIsApp, property)}
          </div>
        </td>
      </tr>
    </Box>
  );
}

ImmoCorseTemplate.propTypes = {
  state: PropTypes.string.isRequired,
  targetIsApp: PropTypes.bool.isRequired,
  property: PropTypes.object.isRequired,
  metadata: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
};
