import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Select from '@material-ui/core/Select';
import { MenuItem, Grid, IconButton, Button } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import {
  categories,
  fetchBanners,
} from '../../Components/SignatureAdmin/utils';
// import { adbBanners } from "../../Components/Signature/utils";
// import bandeau1 from "./img/bandeau-instit1.png";

const useStyles = makeStyles(theme => ({
  root: {
    cursor: 'pointer',
    paddingLeft: '2%',
    paddingRight: '2%',
  },
}));

const BannerDialog = ({
  open,
  setOpen,
  handleClose,
  selectedBanner,
  setSelectedBanner,
  lambdaUrl,
  selectedBrand,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  // const [actualBanner, setActualBanners] = useState(institBanners);
  // const [cat, setCat] = useState("instit");
  const [selectedCat, setSelectedCat] = useState(categories[0].id);
  const [banners, setBanners] = useState(null);

  useEffect(() => {
    if (lambdaUrl && open) fetchBanners(lambdaUrl, setBanners);
    initCategoryByBand();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lambdaUrl, selectedBrand, open]);

  /**
   * Set initial value for category depending on the value of the selected brand
   */
  const initCategoryByBand = () => {
    if (selectedBrand) {
      const selectedCat = categories.find(categorie =>
        categorie.brandsAllowed.includes(selectedBrand.id)
      );
      if (selectedCat) {
        setSelectedCat(selectedCat.id);
      }
    }
  };

  const handleChange = e => {
    setSelectedCat(e.target.value);
  };

  /**
   * Disable category if the brand is not allowed
   * @param {Category} cat
   * @returns {boolean}
   */
  const isCategoryDisable = cat => {
    if (selectedBrand && cat) {
      return !cat.brandsAllowed.includes(selectedBrand.id);
    }
    return false;
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      maxWidth="lg"
      fullWidth={true}
    >
      <h2 id="responsive-dialog-title" style={{ textAlign: 'center' }}>
        {'Choisissez un bandeau de signature à utiliser'}
      </h2>
      <div style={{ margin: 'auto', width: '200px' }}>
        <Select
          defaultValue={categories[0].id}
          value={selectedCat}
          fullWidth
          onChange={handleChange}
        >
          {categories.map(cat => (
            <MenuItem
              key={cat.id}
              value={cat.id}
              disabled={isCategoryDisable(cat)}
            >
              {cat.name}
            </MenuItem>
          ))}
        </Select>
      </div>
      <DialogContent>
        <Grid container spacing={2}>
          {banners
            ? banners
                .filter(banner => banner.category === selectedCat)
                .map(
                  (
                    { name, banner_img_url, id, category, redirect_url },
                    index
                  ) => (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={4}
                      key={id}
                      style={{ cursor: 'pointer' }}
                    >
                      <Card
                        className={classes.root}
                        onClick={() => {
                          setSelectedBanner({
                            index: index,
                            cat: category,
                            src: banner_img_url,
                            bannerLink: redirect_url,
                          });
                          setOpen(false);
                        }}
                      >
                        <CardHeader title={name} />
                        <CardContent>
                          <img
                            width={'100%'}
                            alt={name}
                            src={banner_img_url}
                            title="Foncia bandeau de signature"
                          />
                        </CardContent>
                        <CardActions>
                          <IconButton
                            aria-label="Sélectionner cette bannière"
                            style={{ margin: 'auto' }}
                          >
                            <FavoriteIcon
                              color={
                                selectedBanner.index === index &&
                                selectedBanner.cat === selectedCat
                                  ? 'secondary'
                                  : 'primary'
                              }
                            />
                          </IconButton>
                        </CardActions>
                      </Card>
                    </Grid>
                  )
                )
            : 'Chargement'}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" autoFocus>
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

BannerDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  selectedBanner: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }).isRequired,
  setSelectedBanner: PropTypes.func.isRequired,
  lambdaUrl: PropTypes.string,
};

export default BannerDialog;
