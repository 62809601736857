import React from "react";
import { Redirect } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const SignIn = ({ issuer }) => {
  const { authState } = useOktaAuth();
  const classes = useStyles();

  if (authState.isPending) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography className={classes.paper} color="secondary" variant="h5">
            Chargement en cours, merci de patienter quelques instants...
          </Typography>
        </Grid>
      </Grid>
    );
  }
  return authState.isAuthenticated ? (
    <Redirect to={{ pathname: "/" }} />
  ) : (
    <Grid container>
      <Grid item xs={12}>
        <Typography className={classes.paper} color="secondary" variant="h5">
          Vous n'avez pas accès à cette application.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SignIn;
