import React from 'react';
import PropTypes from 'prop-types';
import { Span, A } from 'react-html-email';
import Socials from '../component/Socials';
import { breakAdressFormat, getLineHeight } from '../utils';

const generateFooter = (metadata, targetIsApp, property) => (
  <>
    <Socials metadata={metadata} property={property} />
    <div
      style={{
        lineHeight: getLineHeight(
          targetIsApp,
          property?.website?.style?.lineHeight
        ),
      }}
    >
      <Span fontSize={11}>
        <A
          href={property?.website.url}
          textDecoration={property?.website?.style?.textDecoration ?? 'none'}
        >
          <strong
            style={{
              fontWeight: 'bold',
              ...property?.website?.style,
            }}
          >
            <span style={{ color: '#F0AB32' }}>vacances</span><span style={{ color: '#033f81' }}>.foncia.com</span>
          </strong>
        </A>
      </Span>
    </div>
  </>
);

export default function VacancesFonciaTemplate({
  state,
  targetIsApp,
  property,
  metadata,
  isMobile,
  footer,
  phoneSeparator,
  shouldBreakAddress = false,
}) {
  return (
    <td
      style={{
        verticalAlign: metadata?.bottomBorderColor ? 'bottom' : 'middle',
        borderBottom: metadata?.bottomBorderColor
          ? `2px solid ${metadata?.bottomBorderColor}`
          : 'none',
        paddingBottom:
          // eslint-disable-next-line no-nested-ternary
          isMobile && metadata?.bottomBorderColor
            ? '3.7px'
            : targetIsApp && metadata?.bottomBorderColor
            ? '0px'
            : '-8px',
      }}
    >
      <div>
        {property?.name?.topMargin && (
          <div>
            <Span>
              <div style={{ lineHeight: '10px', fontSize: '10px' }}>&nbsp;</div>
            </Span>
          </div>
        )}
        <div
          id="name"
          style={{
            lineHeight: getLineHeight(
              targetIsApp,
              property?.name?.style?.lineHeight
            ),
          }}
        >
          <Span
            style={{
              ...property?.name?.style,
              fontWeight: property?.name?.style?.fontWeight ?? 'bold',
              fontSize: property?.name?.style?.fontSize ?? 11,
            }}
          >
            {state[0].value || 'Prénom'} {state[1].value || 'Nom'}
          </Span>
        </div>
        <div
          id="position"
          style={{
            lineHeight: getLineHeight(
              targetIsApp,
              property?.position?.style?.lineHeight
            ),
          }}
        >
          <Span
            style={{
              ...property?.position?.style,
              fontSize: property?.position?.style?.fontSize ?? 11,
            }}
          >
            {state[2].value || 'Fonction'}
          </Span>
        </div>
        {property?.name?.bottomMargin && (
          <div>
            <Span>
              <div style={{ lineHeight: '10px', fontSize: '10px' }}>&nbsp;</div>
            </Span>
          </div>
        )}
        {property?.agency && state[3].value && (
          <div
            id="agency"
            style={{
              lineHeight: getLineHeight(
                targetIsApp,
                property?.agency?.style?.lineHeight
              ),
            }}
          >
            <Span
              fontSize={11}
              style={{
                ...property?.agency?.style,
                fontWeight: property?.agency?.style?.fontWeight ?? 'bold',
              }}
            >
              {state[3].value || 'Agence ou Service'}
            </Span>
          </div>
        )}
        <div
          id="address"
          style={{
            lineHeight: getLineHeight(
              targetIsApp,
              property?.body?.style?.lineHeight
            ),
          }}
        >
          <Span
            fontSize={11}
            style={{
              ...property?.body?.style,
            }}
          >
            {state[4].value
              ? breakAdressFormat(state[4].value, shouldBreakAddress)
              : 'Adresse'}
          </Span>
        </div>
        {(state[5].value || state[6].value) && (
          <div
            id="phone"
            style={{
              lineHeight: getLineHeight(
                targetIsApp,
                property?.body?.style?.lineHeight
              ),
            }}
          >
            <Span
              fontSize={11}
              style={{
                ...property?.body?.style,
              }}
            >
              {state[5].value && state[5].value.replace(/\./g, ' ')}
              {state[5].value &&
                state[6].value &&
                (phoneSeparator || <>&nbsp;-&nbsp;</>)}
              {state[6].value && state[6].value.replace(/\./g, ' ')}
            </Span>
          </div>
        )}
        <div
          id="mail"
          style={{
            lineHeight: getLineHeight(
              targetIsApp,
              property?.body?.style?.lineHeight
            ),
          }}
        >
          <Span fontSize={11}>
            <A
              href={`mailto:${state[7].value}`}
              textDecoration="none"
              style={{
                ...property?.mail?.style,
                textDecoration: 'none',
              }}
            >
              {state[7].value || 'Adresse mail'}
            </A>
          </Span>
        </div>
        {property?.socials?.topMargin && (
          <div>
            <Span>
              <div style={{ lineHeight: '10px', fontSize: '10px' }}>&nbsp;</div>
            </Span>
          </div>
        )}
        {footer || generateFooter(metadata, targetIsApp, property)}
      </div>
    </td>
  );
}

VacancesFonciaTemplate.propTypes = {
  state: PropTypes.string.isRequired,
  targetIsApp: PropTypes.bool.isRequired,
  property: PropTypes.object.isRequired,
  metadata: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
};
