import React, { useState, useEffect } from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {Button, Grid, TableHead} from "@material-ui/core";
import {deleteLecteur, fetchLecteurs} from "../../Components/API";
import ConfirmationDialog from "./ConfirmationDialog";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import {Link} from "react-router-dom";
import {useSnackbar} from "notistack";
import SearchBarHeader from "./SearchBarHeader";
import {columnsLecteurs, itemNames, NoDataTable, TableFooterCustom, TableHeader} from "./Common";
import AddItems from "./AddItems";

const useStyles = makeStyles((theme) =>
	createStyles({
		buttonModify: theme.typography.mainButton,
		buttonDelete: theme.typography.secondaryButton
	})
);

const ListLecteurReseaux = ({ lambdaUrl }) => {
	const [loading, setLoading] = useState(false);
	const [searchVal, setSearchVal] = useState("");
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [listLecteurs, setListLecteurs] = useState(null);
	const [listLecteursInUse, setListLecteursInUse] = useState(null);
	const [showConfirmDialog, setShowConfirmDialog] = useState(false);
	const [idLecteurToDelete, setIdLecteurToDelete] = useState(null);

	const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();
	
	useEffect( () => {
		setLoading(true);
		if (lambdaUrl) {
			fetchLecteurs(lambdaUrl, setListLecteurs)
				.then( res => {
					setListLecteurs(res);
					setListLecteursInUse(res);
					setLoading(false);
				})
				.catch(err => {
					console.log("Error fetching list lecteurs : ",err);
					setLoading(false);
				});
		}
	}, [lambdaUrl]);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};
	
	const openConfirmDeleteDialog = (idLecteur) => {
		setIdLecteurToDelete(idLecteur);
		setShowConfirmDialog(true);
	}
	
	const closeConfirmationDialog = () => {
		setShowConfirmDialog(false);
	}
	
	const submitDelete = () => {
		deleteLecteur(lambdaUrl, idLecteurToDelete)
			.then( () => {
				fetchLecteurs(lambdaUrl, setListLecteurs)
					.then( res => {
						setListLecteurs(res);
						setListLecteursInUse(res);
						enqueueSnackbar(
							"Supprimer le lecteur réseau avec succes!",
							{
								variant: "success",
								autoHideDuration: 3000
							}
						);
					});
				setShowConfirmDialog(false);
			})
			.catch(err => {
				console.log("Error fetching list lecteurs : ",err);
				setShowConfirmDialog(false);
				enqueueSnackbar(
					"Une erreur est survenu lors de la tentative de supprimer le lecteur réseau!",
					{
						variant: "error",
						autoHideDuration: 3000
					}
				);
			});
	}
	
	const handleSearch = (searchVal) => {
		if (!listLecteurs) {
			return;
		}
		return listLecteurs.filter((row) => {
			return (row.type.toLowerCase().includes(searchVal.toLowerCase()) ||
				row.type_detail.toLowerCase().includes(searchVal.toLowerCase()) ||
				row.libelle.toLowerCase().includes(searchVal.toLowerCase()) ||
				row.lecteur_reseau.toLowerCase().includes(searchVal.toLowerCase())
			);
		});
	};
	
	return (
		<React.Fragment>
			<Grid item xs={12}>
				<SearchBarHeader
					searchVal={searchVal}
					setSearchVal={setSearchVal}
					handleSearch={handleSearch}
					setListInUse={setListLecteursInUse}
				/>
				<Grid item xs={12}>
					<AddItems
						itemName={itemNames.lecteur}
						lambdaUrl={lambdaUrl}
						setList={setListLecteurs}
						setListInUse={setListLecteursInUse}
					/>
				</Grid>
				<TableContainer component={Paper}>
					<Table >
						<TableHead>
							<TableHeader columns={columnsLecteurs}/>
						</TableHead>
						<TableBody>
							{ listLecteursInUse && listLecteursInUse.length ?
								listLecteursInUse.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((lecteur) => (
								<TableRow key={lecteur.id+lecteur.type_detail}>
									<TableCell align="center" >{lecteur.id}</TableCell>
									<TableCell align="center">{lecteur.type}</TableCell>
									<TableCell align="center">{lecteur.type_detail}</TableCell>
									<TableCell align="center">{lecteur.lecteur_reseau}</TableCell>
									<TableCell align="center">{lecteur.libelle}</TableCell>
									<TableCell align="center">{lecteur.valide.toString()}</TableCell>
									<TableCell align="center">{lecteur.date_creation.replace(/[TZ]/g, " ")}</TableCell>
									<TableCell align="center">{lecteur.date_maj ? lecteur.date_maj.replace(/[TZ]/g, " ") : ""}</TableCell>
									<TableCell align="center">
										<Link to={{pathname: "admin/modifierLecteur/"+lecteur.id, params: {lecteur: lecteur, lambdaUrl: lambdaUrl}}} style={{ textDecoration: 'none' }}>
											<Button className={classes.buttonModify} variant="contained" >Modifier</Button>
										</Link>
									</TableCell>
									<TableCell align="center">
										<Button className={classes.buttonDelete} variant="contained" onClick={ () => openConfirmDeleteDialog(lecteur.id)}>Supprimer</Button>
									</TableCell>
								</TableRow>
							))
								:
								<NoDataTable loading={loading} />
							}
						</TableBody>
						<TableFooter>
							<TableFooterCustom
								list={listLecteurs} 
								rowsPerPage={rowsPerPage} 
								page={page} 
								handleChangePage={handleChangePage}
								handleChangeRowsPerPage={handleChangeRowsPerPage}
							/>
						</TableFooter>
					</Table>
				</TableContainer>
			</Grid>
			<Grid item xs={6}>
				<ConfirmationDialog visible={showConfirmDialog} text="Voulez-vous supprimer le lecteur réseau ?" onConfirm={submitDelete} onCancel={closeConfirmationDialog} />
			</Grid>
		</React.Fragment>
	)
}

export default ListLecteurReseaux;