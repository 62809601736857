const initState = [
  {
    value: '',
    label: 'Prenom',
    id: 'prenom',
    wide: false,
  },
  {
    value: '',
    label: 'Nom',
    id: 'nom',
    wide: false,
  },
  {
    value: '',
    label: 'Fonction',
    id: 'fonction',
    wide: false,
  },
  {
    value: '',
    label: 'Agence ou Service',
    id: 'libelle',
    wide: false,
  },
  {
    value: '',
    label: 'Adresse',
    id: 'adresse',
    wide: true,
  },
  {
    value: '',
    label: 'Numéro fixe',
    id: 'telephone',
    wide: false,
  },
  {
    value: '',
    label: 'Numéro mobile',
    id: 'telephoneMobile',
    wide: false,
  },
  {
    value: '',
    label: 'Adresse mail',
    id: 'email',
    wide: true,
  },
];

const getBannerLink = (selectedBanner) => {
  let link = 'https://fr.foncia.com/';
  if (selectedBanner.cat === 'instit') {
    switch (selectedBanner.index) {
      case 2:
      case 3:
      case 4:
        link += 'notre-ambition';
        break;
      case 5:
        link += 'mon-guide-immo';
        break;
      default:
        break;
    }
  } else if (selectedBanner.cat === 'partner') {
    link +=
      'notre-ambition/avec-foncia-les-matchs-du-racing-92-se-jouent-adomicile';
  } else if (selectedBanner.cat === 'adb') {
    switch (selectedBanner.index) {
      case 1:
      case 4:
      case 5:
        link +=
          'faire-gerer-ma-location-par-foncia/la-garantie-loyer-impaye?gclid=EAIaIQobChMI0uqAsobO5AIVgvdRCh2ncAAZEAAYASAAEgKwJvD_BwE';
        break;
      case 2:
        link += 'location';
        break;
      case 3:
        link += 'ma-location-c-est-facile-avec-foncia';
        break;
      case 6:
        link += 'le-demenagement-et-l-installation'; // BROKEN
        break;
      case 7:
        link += 'estimer-et-vendre/la-signature-electronique-en-immobilier';
        break;
      case 8:
        link += 'estimation-loyer';
        break;
      case 9:
        link += 'faire-gerer-ma-location-par-foncia';
        break;
      case 10:
        link += 'login';
        break;
      case 11:
      case 12:
        link += 'syndic-de-copropriete';
        break;
      case 13:
        link += 'syndic-de-copropriete/assistance-foncia-24-7-en-cas-d-urgence';
        break;
      default:
        break;
    }
  } else if (selectedBanner.cat === 'buy') {
    switch (selectedBanner.index) {
      case 1:
        link += 'parrainage';
        break;
      case 2:
        link += 'estimer-et-vendre/mandat-de-vente-foncia-premium';
        break;
      case 3:
        link += 'votre-achat-dans-l-immobilier-neuf-avec-foncia';
        break;
      case 4:
        link += 'estimer-et-vendre/mandat-de-vente-foncia-premium';
        break;
      case 5:
        link +=
          'estimer-et-vendre/diagnostics-immobiliers-et-constats-obligatoires';
        break;
      case 6:
        link +=
          'parrestimer-et-vendre/la-signature-electronique-en-immobilierainage';
        break;
      case 7:
        link += 'achat';
        break;
      case 8:
        // BROKEN
        link =
          'https://www.immodvisor.com/recherche?activity=&name=foncia&city=';
        break;
      case 9:
        link += 'estimer-son-bien';
        break;
      default:
        break;
    }
  } else if (selectedBanner.cat === 'invest') {
    switch (selectedBanner.index) {
      case 1:
        link += 'l-investissement-immobilier/gestion-de-patrimoine';
        break;
      case 2:
        link += 'l-investissement-immobilier/foncia-valorisation';
        break;
      default:
        break;
    }
  }
  return link;
};

/**
 * Get an url to return only his domain (eg. https://mywebsite.com => mywebsite.com)
 * @param {string} url - url to display
 * @returns {string}
 */
const formatUrlForSignatures = (url) => {
  const validateUrlPattern =
    /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;
  // Check if url is correct
  if (validateUrlPattern.test(url)) {
    // split url to get only the url domain
    const splittedUrl = url.split(/^https?:\/\/(?:www\.)?([a-z]{0,2}\.)?/);
    return splittedUrl[splittedUrl.length - 1];
  }
  return url;
};

/**
 * Get a lineHeight and remove a percentage amount for app display
 * @param {string} lineHeight
 * @returns new value for lineHeight
 */
const computeLineHeightForApp = (lineHeight) => {
  const ratio = 0.165;
  // Get only the lineHeight number and parse to number
  const lineHeightValue = parseInt(lineHeight.replace('px', ''), 10);
  // Build the lineHeight value with px
  return `${lineHeightValue - lineHeightValue * ratio}px`;
};

/**
 * Get a default lineHeight and compute a new line height if necessary depending of targetIsApp
 * @param {string} defaultLineHeight
 * @param {boolean} targetIsApp
 * @returns {string} new value for lineHeight
 */
const getLineHeight = (targetIsApp, newLineHeight) => {
  const lineHeight = newLineHeight ?? '14.5px';

  return targetIsApp ? lineHeight : computeLineHeightForApp(lineHeight);
};

/**
 * Break address into 2 lines if shouldBreakAddress is true
 * @param {string} address
 * @param {boolean} shouldBreakAddress
 * @returns {string}
 */
const breakAdressFormat = (address, shouldBreakAddress) => {
  if (address && shouldBreakAddress) {
    const regex = /^(.*?)(\b\d{5}\b)(.*)/;
    if (regex.test(address)) {
      const [, address1, postalCode, address2] = address.match(regex);
      if ((address1, postalCode, address2)) {
        return (
          <>
            {address1.trim()}
            <br style={{ msoDataPlacement: 'same-cell' }} />
            {postalCode.trim()} {address2.trim()}
          </>
        );
      }
    }
  }
  return address;
};

/**
 * Return only the city from address, city is all string extracted after postal code
 * @param {string} address
 * @returns {string}
 */
const getCityFormAddress = (address) => {
  const regex = /^(.*?)(\b\d{5}\b)(.*)/;
  if (regex.test(address)) {
    const [, , , city] = address.match(regex);
    return city.trim();
  }
};

export {
  initState,
  getBannerLink,
  formatUrlForSignatures,
  getLineHeight,
  breakAdressFormat,
  getCityFormAddress,
};
