import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { addLecteur, addUserRole, itemNames, SelectField } from './Common';
import ConfirmationDialog from './ConfirmationDialog';
import CheckIcon from '@material-ui/icons/Check';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import {
  addNewLecteur,
  addNewUserRole,
  fetchLecteurs,
  fetchUserRoles,
} from '../../Components/API';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme =>
  createStyles({
    buttonAddItem: theme.typography.mainButton,
    buttonCancel: theme.typography.secondaryButton,
    inputField: {
      margin: theme.spacing(1),
      width: '80%',
    },
  })
);

const AddItems = ({ itemName, lambdaUrl, setList, setListInUse }) => {
  const [isLecteur, setIsLecteur] = useState(true);
  const [showAddFields, setShowAddFields] = useState(false);
  const [showAddButton, setShowAddButton] = useState(true);
  const [colums, setColums] = useState(addLecteur);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const [form, setForm] = useState({});

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleChangeInput = (e, idField) => {
    form[idField] = e.target.value;
    setForm({ ...form });
  };

  const handleChangeSelectInput = (e, idField) => {
    form[idField] = e;
    setForm({ ...form });
  };

  const handleAbandonButton = () => {
    setShowAddButton(true);
    setShowAddFields(false);
  };

  const handleAddButton = () => {
    setShowAddButton(false);
    setShowAddFields(true);
  };

  const openConfirmDialog = () => {
    setShowConfirmDialog(true);
  };

  const closeConfirmationDialog = () => {
    setShowConfirmDialog(false);
  };

  const setDefaultState = () => {
    setShowAddButton(true);
    setShowAddFields(false);
  };

  const submitAddItem = () => {
    if (isLecteur) {
      addNewLecteur(lambdaUrl, 'fr', form, enqueueSnackbar)
        .then(() => {
          fetchLecteurs(lambdaUrl, setList).then(res => {
            setList(res);
            setListInUse(res);
          });
          setShowConfirmDialog(false);
          setDefaultState();
        })
        .catch(err => {
          console.log('Error adding lecteur reseau : ', err);
          setShowConfirmDialog(false);
          setDefaultState();
        });
    } else {
      addNewUserRole(lambdaUrl, 'fr', form, enqueueSnackbar)
        .then(() => {
          fetchUserRoles(lambdaUrl, setList).then(res => {
            setList(res);
            setListInUse(res);
          });
          setShowConfirmDialog(false);
          setDefaultState();
        })
        .catch(err => {
          console.log('Error adding user roles : ', err);
          setShowConfirmDialog(false);
          setDefaultState();
        });
    }
  };

  const initForm = formSettings => {
    const newForm = {};
    for (const row of formSettings) {
      newForm[row.idField] = row.defaultValue ?? '';
    }
    setForm(newForm);
  };

  useEffect(() => {
    if (itemNames.userRole === itemName) {
      setIsLecteur(false);
      setColums(addUserRole);
      initForm(addUserRole);
    } else {
      initForm(addLecteur);
    }
  }, [itemName, colums]);

  return (
    <section>
      <Grid container spacing={2}>
        {showAddButton && (
          <Grid item xs={12}>
            <Button
              className={classes.buttonAddItem}
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleAddButton}
            >
              {isLecteur
                ? 'Ajouter un lecteur réseau'
                : 'Ajouter un rôle utilisateur'}
            </Button>
          </Grid>
        )}
        <Grid container item xs={12}>
          {showAddFields &&
            colums.map(col => (
              <Grid container item xs={3} key={col.idField}>
                {col.isHidden?.(form) ? (
                  <></>
                ) : col.component ? (
                  <SelectField
                    value={form[col.idField]}
                    options={col.options}
                    handleChange={e => handleChangeSelectInput(e, col.idField)}
                    className={classes.inputField}
                    label={col.label}
                    helperText={col.helperText}
                    required={col.required}
                  />
                ) : (
                  <TextField
                    id={col.idField}
                    required
                    className={classes.inputField}
                    label={col.label}
                    helperText={col.helperText}
                    value={form[col.idField].value}
                    onChange={event => handleChangeInput(event, col.idField)}
                  />
                )}
              </Grid>
            ))}
        </Grid>
        {showAddFields && (
          <Grid container item xs={12}>
            <Grid item xs={3}>
              <Button
                className={classes.buttonAddItem}
                variant="contained"
                startIcon={<CheckIcon />}
                onClick={openConfirmDialog}
              >
                Confirmer
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                className={classes.buttonCancel}
                variant="contained"
                startIcon={<ClearIcon />}
                onClick={handleAbandonButton}
              >
                Abandonner
              </Button>
            </Grid>
          </Grid>
        )}
        <Grid item xs={6}>
          <ConfirmationDialog
            visible={showConfirmDialog}
            text={
              'Voulez-vous ajouter ' +
              (isLecteur ? 'le lecteur réseaux ?' : 'le rôle utilisateur ?')
            }
            onConfirm={submitAddItem}
            onCancel={closeConfirmationDialog}
          />
        </Grid>
      </Grid>
    </section>
  );
};

export default AddItems;
