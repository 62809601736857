import React from 'react';
import PropTypes from 'prop-types';
import { Span, A, Box } from 'react-html-email';
import Socials from '../component/Socials';
import Website from '../component/Website';
import { getLineHeight } from '../utils';
import Image from '../component/Image';

export default function AssurimoTemplate({
  state,
  targetIsApp,
  property,
  metadata,
  isMobile,
  selectedBrand,
}) {
  return (
    <Box cellPadding={0} cellSpacing={0} style={{ borderCollapse: 'collapse' }}>
      <tr>
        <Image
          property={property}
          selectedBrand={selectedBrand}
          isMobile={isMobile}
        />
        <td
          style={{
            borderLeft: '1px solid #F2F2F2',
            borderRight: '1px solid #F2F2F2',
            padding: '0 5pt',
            // eslint-disable-next-line no-nested-ternary
            paddingBottom: isMobile ? '3.7px' : targetIsApp ? '0px' : '0px',
          }}
        >
          <div
            style={{
              lineHeight: getLineHeight(targetIsApp),
              textAlign: 'center',
            }}
          >
            <Span
              style={{
                color: '#0D3788',
                fontSize: '18px',
                fontWeight: 'bold',
              }}
            >
              {state[0].value || 'Prénom'} {state[1].value || 'Nom'}
            </Span>
          </div>
          <div
            style={{
              lineHeight: getLineHeight(targetIsApp),
              textAlign: 'center',
            }}
          >
            <Span
              style={{
                fontSize: 11,
                color: '#0D3788',
              }}
            >
              {state[2].value || 'Fonction'}
            </Span>
          </div>
          {state[3].value && (
            <div>
              <div
                style={{
                  lineHeight: getLineHeight(targetIsApp),
                  textAlign: 'center',
                }}
              >
                <Span
                  fontSize={11}
                  style={{
                    color: '#0D3788',
                    fontWeight: 'initial',
                  }}
                >
                  {state[3].value || 'Agence ou Service'}
                </Span>
              </div>
            </div>
          )}
        </td>
        <td style={{ paddingLeft: '10pt' }}>
          <div>
            {(state[5].value || state[6].value) && (
              <div style={{ lineHeight: getLineHeight(targetIsApp) }}>
                <Span
                  fontSize={11}
                  style={{
                    color: '#0D3788',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {state[5].value && state[5].value.replace(/\./g, ' ')}
                  {state[5].value && state[6].value && <>&nbsp;-&nbsp;</>}
                  {state[6].value && state[6].value.replace(/\./g, ' ')}
                </Span>
              </div>
            )}
            <div style={{ lineHeight: getLineHeight(targetIsApp) }}>
              <Span fontSize={11} style={{ whiteSpace: 'nowrap' }}>
                <A
                  href={`mailto:${state[7].value}`}
                  textDecoration="none"
                  style={{
                    color: '#0D3788',
                    textDecoration: 'none',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {state[7].value || 'Adresse mail'}
                </A>
              </Span>
            </div>

            <Box align="center" width="100%">
              <tr>
                <td>
                  {/* WEBSITE */}
                  <Website targetIsApp={targetIsApp} property={property} />
                </td>
                <td align="right">
                  {/* SOCIALS */}
                  <Socials metadata={metadata} />
                </td>
              </tr>
            </Box>

            <div>
              <Span
                style={{
                  lineHeight: getLineHeight(targetIsApp, '10px'),
                  color: '#5d5d5d',
                  whiteSpace: 'nowrap',
                }}
                fontSize={10}
              >
                N° ORIAS : 09 049 623 Paris La Défense <br />
                N° ORIAS : 07 000 790 Lyon 7ème
              </Span>
            </div>
          </div>
        </td>
      </tr>
    </Box>
  );
}

AssurimoTemplate.propTypes = {
  state: PropTypes.string.isRequired,
  targetIsApp: PropTypes.bool.isRequired,
  property: PropTypes.shape({}).isRequired,
  metadata: PropTypes.shape({}).isRequired,
  isMobile: PropTypes.bool.isRequired,
  selectedBrand: PropTypes.string.isRequired,
};
