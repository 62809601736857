let adbBanners = [];
for (let i = 1; i < 14; i++) {
  adbBanners.push({
    alt: `Bandeau ADB ${i}`,
    src: `https://apple-helper.qualif.fonciatech.net/banner/adb/adb${i}.png`,
  });
}

let institBanners = [];
for (let i = 1; i < 6; i++) {
  institBanners.push({
    alt: `Bandeau institutionnel ${i}`,
    src: `https://apple-helper.qualif.fonciatech.net/banner/institutional/instit${i}.png`,
  });
}

let partnerBanners = [];
for (let i = 1; i < 4; i++) {
  partnerBanners.push({
    alt: `Bandeau partenaire ${i}`,
    src: `https://apple-helper.qualif.fonciatech.net/banner/partnership/partner${i}.png`,
  });
}

let buyBanners = [];
for (let i = 1; i < 4; i++) {
  buyBanners.push({
    alt: `Bandeau achat et vente ${i}`,
    src: `https://apple-helper.qualif.fonciatech.net/banner/buy/buy${i}.png`,
  });
}

let investmentBanners = [];
for (let i = 1; i < 4; i++) {
  investmentBanners.push({
    alt: `Bandeau investissement ${i}`,
    src: `https://apple-helper.qualif.fonciatech.net/banner/investissement/invest${i}.png`,
  });
}

const explicationsList = [
  {
    title: "Dans Outlook sur iPhone / iPad",
    label: "import signature Outlook iPhone iPad",
    items: [
      'Aller sur Apple Helper sur votre téléphone pour générer votre signature et cliquer sur "Copier la signature"',
      "Cliquer sur votre profil en haut à gauche",
      "Cliquer sur la roue crantée en bas à gauche (réglages)",
      "Cliquer sur Signature",
      "Coller la signature",
    ],
  },
  {
    title: "Dans Mail sur iPhone / iPad",
    label: "import signature Mail iPhone iPad",
    items: [
      "Aller dans Réglages",
      "Cliquer sur Mail",
      "Sélectionner le compte souhaité",
      "Coller la signature",
      "Secouer votre iPad ou iPhone",
      "Répondre Oui à Annuler Modifier les attributs afin que la mise en page s'effectue",
    ],
  },
  {
    title: "Dans Outlook sur MacBook",
    label: "import signature outlook MacBook",
    items: [
      "Ouvrir Outlook",
      "Ouvrir un nouveau Mail (nouveau Courrier)",
      "Cliquer sur Signature",
      "Modifier les signatures",
      "Rajouter une signature avec +",
      "Coller votre signature puis enregistrer",
    ],
  },
  {
    title: "Dans Outlook sur un navigateur web",
    label: "import signature outlook navigateur web",
    items: [
      `Rendez-vous sur votre compte Outlook`,
      "Cliquer sur Paramètres (roue crantée, en haut à droite)",
      "Cliquer sur Afficher tous les paramètres d’Outlook",
      "Cliquer sur Composer et répondre",
      "Coller votre signature puis enregistrer",
    ],
  },
];

export {
  adbBanners,
  institBanners,
  partnerBanners,
  buyBanners,
  investmentBanners,
  explicationsList,
};
