import React, { useState, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import fetch from "isomorphic-fetch";
import {
  Divider,
  Grid,
  InputBase,
  LinearProgress,
  List,
  Typography,
} from "@material-ui/core";
import { useSnackbar } from "notistack";

import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";

import { useConfFetch } from "../../hooks/useConfFetch";
import { useStyles } from "./style";

import Repertoire from "./Repertoire";
import Loader from "./Loader";

const groupNames = ["defaultGroups", "customGroups"];

const Repertoires = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const classes = useStyles();
  const [conf, error, loading] = useConfFetch();
  const { enqueueSnackbar } = useSnackbar();

  const [user, setUser] = useState("");
  const [lambdaUrl, setLambdaUrl] = useState(null);
  const [noGroups, setNoGroups] = useState(false);
  const [oktaUserInfoUrl, setOktaUserInfoUrl] = useState(null);
  const [repertoires, setRepertoires] = useState([]);
  const [searchContent, setSearchContent] = useState("");
  const [filteredReperoires, setFilteredRepertoires] = useState([]);
  const [fetching, setFetching] = useState(false);

  async function askApim(email, fonciaId) {
    try {
      await lambdaUrl;
      // const params = { id: "frx21939" };
      // const urlParams = new URLSearchParams(Object.entries(params));
      await fetch(lambdaUrl + "structure-name", {
        method: "POST",
        body: JSON.stringify({ email: email, fonciaId: fonciaId }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res === null) {
            setNoGroups(true);
            setFetching(false);
            return;
          }
          let newRep = {};
          newRep.defaultGroups = res.defaultGroups.map((el) => {
            return { ...el, id: el.refSIGestion };
          });
          newRep.customGroups = res.customGroups.map((el) => {
            return { ...el, id: el.refSIGestion };
          });
          localStorage.setItem("repertoires", JSON.stringify(newRep));
          localStorage.setItem("lastFetch", new Date());
          setRepertoires(newRep);
          setFilteredRepertoires(newRep);
          setSearchContent("");
          setFetching(false);
        });
    } catch (err) {
      console.log("une erreur dans la lambda", err);
      setFetching(false);
      enqueueSnackbar(
        "Une erreur est survenu lors de la tentative de récupération d'informations depuis le serveur.",
        {
          variant: "error",
        }
      );
    }
  }

  useEffect(() => {
    if (!loading && !error && conf) {
      setLambdaUrl(conf.LAMBDA_URL);
      setOktaUserInfoUrl(conf.OKTA_USER_INFO_URL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conf, error, loading]);

  useEffect(() => {
    if (oktaUserInfoUrl !== null) {
      async function fetchData() {
        try {
          let user = await oktaAuth.getUser();
          const today = new Date();
          setUser(user);
          const storedRep = JSON.parse(localStorage.getItem("repertoires"));
          const lastFetch = new Date(
            Date.parse(localStorage.getItem("lastFetch"))
          );
          const fonciaId = /fr(x|)[0-9]*/.exec(user.preferred_username)[0];
          if (storedRep !== null && lastFetch !== null) {
            setRepertoires(storedRep);
            setFilteredRepertoires(storedRep);
            // After 1 days, refetch from the APIM in background
            const oneDay = new Date(lastFetch.getTime() + 24 * 60 * 60 * 1000);
            if (oneDay < today) {
              setFetching(true);
              askApim(user.sub, fonciaId);
            }
          } else {
            setFetching(true);
            askApim(user.sub, fonciaId);
          }
        } catch (err) {
          console.log("Error while fetching", err);
        }
      }
      if (authState.isAuthenticated) {
        fetchData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    oktaUserInfoUrl,
    authState.isAuthenticated,
    authState.accessToken.value,
    oktaAuth,
  ]);

  const filterSearchElement = (currentValue, element) => {
    return element.filter((el) => {
      if (
        el.id.toUpperCase().includes(currentValue.toUpperCase()) ||
        el.nomUsage.toUpperCase().includes(currentValue.toUpperCase())
      ) {
        return el;
      } else {
        return false;
      }
    });
  };
  const handleSearch = (e) => {
    const currentValue = e.target.value;
    setSearchContent(currentValue);
    if (currentValue === "") {
      setFilteredRepertoires(repertoires);
    } else {
      let filteredRep = {};
      filteredRep.customGroups = filterSearchElement(
        currentValue,
        repertoires.customGroups
      );
      filteredRep.defaultGroups = filterSearchElement(
        currentValue,
        repertoires.defaultGroups
      );
      setFilteredRepertoires(filteredRep);
    }
  };

  return (
    <section>
      <Grid container spacing={2}>
        <Grid item xs={12} className={clsx(classes.title, classes.containers)}>
          <Typography variant="h4" align="center" gutterBottom>
            Repertoires distants
          </Typography>
          {fetching ? (
            <LinearProgress variant="indeterminate" color="secondary" />
          ) : (
            <Divider />
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          className={clsx(classes.resultContainer, classes.containers)}
        >
          {noGroups ? (
            <Typography variant="h5" className={classes.title}>
              Vous n'appartenez à aucun groupe vous donnant accès à un
              répertoire distant
            </Typography>
          ) : (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} md={9}>
                  <Typography variant="h5" className={classes.title}>
                    Cliquez sur l'un des répertoires suivants pour l'ouvrir sur
                    votre appareil
                  </Typography>
                </Grid>
                {((repertoires.defaultGroups &&
                  repertoires.defaultGroups.length > 0) ||
                  (repertoires.customGroups &&
                    repertoires.customGroups.length > 0)) && (
                  <Grid item xs={12} md={3}>
                    <div className={classes.search}>
                      <div className={classes.searchIcon}>
                        <SearchIcon />
                      </div>
                      <InputBase
                        placeholder="Filtrer les répertoires"
                        classes={{
                          root: classes.inputRoot,
                          input: classes.inputInput,
                        }}
                        inputProps={{ "aria-label": "search" }}
                        value={searchContent}
                        onChange={handleSearch}
                      />
                    </div>
                  </Grid>
                )}
              </Grid>
              <div className={classes.containers}>
                <List>
                  {(!(repertoires.defaultGroups && repertoires.customGroups) &&
                    loading) ||
                  (!(repertoires.defaultGroups && repertoires.customGroups) &&
                    fetching)
                    ? [1, 2, 3, 4].map((el, index) => {
                        return <Loader key={el} divider={index !== 3} />;
                      })
                    : Object.entries(filteredReperoires).map(([key, value]) => {
                        const userId = /fr(x|)[0-9]*/.exec(
                          user.preferred_username
                        )[0];
                        return value.map((rep, index) => {
                          const link =
                            key === groupNames[0]
                              ? `smb://FRANCE\\${userId}@san${rep.id}.tw.foncia.net/g_frc_${rep.id}`
                              : `smb://FRANCE\\${userId}@${rep.id}`;
                          return (
                            <Repertoire
                              key={rep.id}
                              rep={rep}
                              link={link}
                              index={index}
                              repertoiresLength={
                                filteredReperoires.defaultGroups.length +
                                filteredReperoires.customGroups.length
                              }
                              group={key}
                            />
                          );
                        });
                      })}
                </List>
              </div>
            </>
          )}
        </Grid>
      </Grid>
    </section>
  );
};
export default Repertoires;
