import { BRANDS_ENUM } from '../../Signature/assets/brands';

const categories = [
  {
    id: 'institutional',
    name: 'Institutionnel',
    brandsAllowed: [BRANDS_ENUM.FONCIA],
  },
  {
    id: 'adb',
    name: 'ADB',
    brandsAllowed: [BRANDS_ENUM.FONCIA],
  },
  {
    id: 'partnership',
    name: 'Partenaires',
    brandsAllowed: [BRANDS_ENUM.FONCIA],
  },
  {
    id: 'buy',
    name: 'Achat et vente',
    brandsAllowed: [BRANDS_ENUM.FONCIA],
  },
  {
    id: 'investment',
    name: 'Investissement',
    brandsAllowed: [BRANDS_ENUM.FONCIA],
  },
  {
    id: 'recruitment',
    name: 'Recrutement',
    brandsAllowed: [BRANDS_ENUM.FONCIA],
  },
  {
    id: BRANDS_ENUM.FONCIA_IMMO_NEUF,
    name: BRANDS_ENUM.FONCIA_IMMO_NEUF,
    brandsAllowed: [BRANDS_ENUM.FONCIA_IMMO_NEUF],
  },
  {
    id: BRANDS_ENUM.CONSTATIMMO,
    name: BRANDS_ENUM.CONSTATIMMO,
    brandsAllowed: [BRANDS_ENUM.CONSTATIMMO],
  },
  {
    id: BRANDS_ENUM.ESSET_VALORISATION,
    name: BRANDS_ENUM.ESSET_VALORISATION,
    brandsAllowed: [BRANDS_ENUM.ESSET_VALORISATION],
  },
  {
    id: BRANDS_ENUM.ESSET_PROPERTY,
    name: BRANDS_ENUM.ESSET_PROPERTY,
    brandsAllowed: [BRANDS_ENUM.ESSET_PROPERTY],
  },
  {
    id: BRANDS_ENUM.STARES,
    name: BRANDS_ENUM.STARES,
    brandsAllowed: [BRANDS_ENUM.STARES],
  },
  {
    id: BRANDS_ENUM.ASSURIMO,
    name: BRANDS_ENUM.ASSURIMO,
    brandsAllowed: [BRANDS_ENUM.ASSURIMO],
  },
  {
    id: BRANDS_ENUM.TECH_WAY,
    name: BRANDS_ENUM.TECH_WAY.split(' ').join('-'),
    brandsAllowed: [BRANDS_ENUM.TECH_WAY],
  },
  {
    id: BRANDS_ENUM.IMMO_CORSE,
    name: BRANDS_ENUM.IMMO_CORSE,
    brandsAllowed: [BRANDS_ENUM.IMMO_CORSE],
  },
  {
    id: BRANDS_ENUM.GSI,
    name: BRANDS_ENUM.GSI,
    brandsAllowed: [BRANDS_ENUM.GSI],
  },
  {
    id: BRANDS_ENUM.FONCIA_VACANCES,
    name: BRANDS_ENUM.FONCIA_VACANCES,
    brandsAllowed: [BRANDS_ENUM.FONCIA_VACANCES],
  },
];

export default categories;
