import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    margin: theme.spacing(1, 0, 0),
  },
  addButton: {
    float: "right",
    marginRight: 25,
  },
  root: {
    cursor: "pointer",
    paddingLeft: "2%",
    paddingRight: "2%",
    marginBottom: "2%",
  },
  formControl: {
    width: "40%",
    marginLeft: "30%",
    marginBottom: 50,
  },
  formContainer: {
    justifyContent: "space-between",
  },
  containers: {
    backgroundColor: theme.palette.background.paper,
  },
  imgsContainer: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  logo: {
    cursor: "pointer",
    padding: "6px",
    border: "1px solid transparent",
  },
  selectedLogo: {
    border: "1px solid #ed6905",
    borderRadius: "10px",
  },
  signatureContainer: {
    border: "1px solid #ee6c0e",
    // height: "180px", // IF NO BANNER
    height: "320px",
    padding: "10px",
    width: "100%",
    borderRadius: "10px",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  copyButton: {
    marginTop: "22px",
  },
  tutoTitle: {
    textAlign: "center",
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default useStyles;
