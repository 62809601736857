import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Button, TextField } from '@material-ui/core';
import { useLocation, Link } from 'react-router-dom';
import {
  SelectField,
  updateLecteurColumns,
  updateUserRoleColumns,
} from './Common';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { updateLecteur, updateUserRole } from '../../Components/API';
import ConfirmationDialog from './ConfirmationDialog';
import clsx from 'clsx';

const useStyles = makeStyles(theme =>
  createStyles({
    buttonConfirm: theme.typography.mainButton,
    marginButton: {
      marginRight: '5px',
    },
    buttonCancel: theme.typography.secondaryButton,
    inputField: {
      margin: theme.spacing(1),
      width: '80%',
    },
  })
);

export const UpdateLecteur = () => {
  // const { id } = useParams();
  const { params } = useLocation();

  const [lecteurUpdate, setLecteurUpdate] = useState(params.lecteur);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const handleChangeInput = (event, idField) => {
    lecteurUpdate[idField] = event.target.value;
    setLecteurUpdate(lecteurUpdate);
  };

  const handleChangeSelectInput = e => {
    lecteurUpdate.valide = e;
    setLecteurUpdate(lecteurUpdate);
  };

  const openConfirmDialog = () => {
    setShowConfirmDialog(true);
  };

  const closeConfirmationDialog = () => {
    setShowConfirmDialog(false);
  };

  const submitModify = () => {
    updateLecteur(params.lambdaUrl, 'fr', lecteurUpdate, enqueueSnackbar)
      .then(() => {
        setShowConfirmDialog(false);
      })
      .catch(err => {
        console.log('Error modify lecteur reseau : ', err);
        setShowConfirmDialog(false);
      });
  };

  return (
    <section>
      <UpdateBody
        colums={updateLecteurColumns}
        classes={classes}
        dataUpdate={params.lecteur}
        handleChangeInput={handleChangeInput}
        handleChangeSelectInput={handleChangeSelectInput}
        openConfirmDialog={openConfirmDialog}
      />
      <Grid item xs={6}>
        <ConfirmationDialog
          visible={showConfirmDialog}
          text={'Voulez-vous modifier le lecteur'}
          onConfirm={submitModify}
          onCancel={closeConfirmationDialog}
        />
      </Grid>
    </section>
  );
};

export const UpdateUserRole = () => {
  // const { id } = useParams();
  const { params } = useLocation();

  const [userRoleUpdate, setUserRoleUpdate] = useState(params.userRole);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const handleChangeInput = (event, idField) => {
    userRoleUpdate[idField] = event.target.value;
    setUserRoleUpdate({ ...userRoleUpdate });
  };

  const handleChangeSelectInput = (value, key) => {
    userRoleUpdate[key] = value;
    setUserRoleUpdate({ ...userRoleUpdate });
  };

  const openConfirmDialog = () => {
    setShowConfirmDialog(true);
  };

  const closeConfirmationDialog = () => {
    setShowConfirmDialog(false);
  };

  const submitModify = () => {
    updateUserRole(params.lambdaUrl, 'fr', userRoleUpdate, enqueueSnackbar)
      .then(() => {
        setShowConfirmDialog(false);
      })
      .catch(err => {
        console.log('Error modify user rôle : ', err);
        setShowConfirmDialog(false);
      });
  };

  return (
    <section>
      <UpdateBody
        colums={updateUserRoleColumns}
        classes={classes}
        dataUpdate={userRoleUpdate}
        handleChangeInput={handleChangeInput}
        handleChangeSelectInput={handleChangeSelectInput}
        openConfirmDialog={openConfirmDialog}
      />
      <Grid item xs={6}>
        <ConfirmationDialog
          visible={showConfirmDialog}
          text={'Voulez-vous modifier la donnée'}
          onConfirm={submitModify}
          onCancel={closeConfirmationDialog}
        />
      </Grid>
    </section>
  );
};

const UpdateBody = ({
  colums,
  classes,
  dataUpdate,
  handleChangeInput,
  handleChangeSelectInput,
  openConfirmDialog,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid container item xs={12}>
        {colums.map(item =>
          item.isHidden?.(dataUpdate) ? (
            <></>
          ) : (
            <Grid item xs={4} key={item.idField}>
              {item.component ? (
                <SelectField
                  value={dataUpdate[item.idField]}
                  options={item.options}
                  handleChange={e => handleChangeSelectInput(e, item.idField)}
                  className={classes.inputField}
                  label={item.label}
                  helperText={item.helperText}
                />
              ) : (
                <TextField
                  id={item.idField}
                  disabled={item.readOnly}
                  required={!item.readOnly}
                  className={classes.inputField}
                  label={item.label}
                  value={dataUpdate[item.idField]}
                  helperText={item.helperText}
                  onChange={event => handleChangeInput(event, item.idField)}
                />
              )}
            </Grid>
          )
        )}
      </Grid>
      <Grid item xs={4}>
        <Button
          className={clsx(classes.buttonConfirm, classes.marginButton)}
          variant="contained"
          onClick={openConfirmDialog}
        >
          Confirmer la modification
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Link to={{ pathname: '/admin' }} style={{ textDecoration: 'none' }}>
          <Button
            className={clsx(classes.buttonCancel, classes.marginButton)}
            variant="contained"
          >
            Retourner à l'administration
          </Button>
        </Link>
      </Grid>
    </Grid>
  );
};
