async function askInfo(fr, lambdaUrl, state, setState) {
  try {
    // await fetch(lambdaUrl + "user-info?" + new URLSearchParams({ fr: fr }), {
    await fetch(lambdaUrl + 'user-info', {
      method: 'POST',
      body: JSON.stringify({ fr: fr }),
    })
      .then(res => res.json())
      .then(res => {
        let newState = [...state];
        // Loop through the 1st level of the object res
        Object.keys(res).forEach(el => {
          newState.map(attribut => {
            if (attribut.id === el) {
              return (attribut.value = res[el]);
            } else return '';
          });
        });
        let codePostal = '',
          ville = '',
          adresseLigne1 = '';
        // Loop through the intricate individuRattachements level
        res.individuRattachements.forEach(rattachement => {
          if (rattachement.principal) {
            let rattachType;
            // Rattachement can either be an agence or a societe
            if (rattachement.agence) {
              rattachType = rattachement.agence;
            } else if (rattachement.societe) {
              rattachType = rattachement.societe;
            }
            Object.keys(rattachType).forEach(agence => {
              // Gather codePostal, ville & adresseLigne1 to build the complete adress ligne
              if (agence === 'codePostal') codePostal = rattachType[agence];
              else if (agence === 'ville')
                ville =
                  rattachType[agence].charAt(0) +
                  rattachType[agence].slice(1).toLowerCase();
              else if (agence === 'adresseLigne1')
                adresseLigne1 = rattachType[agence];
              newState.map(attribut => {
                if (attribut.id === agence && attribut.value === '') {
                  return (attribut.value = rattachType[agence]);
                } else if (attribut.id === 'adresse') {
                  return (attribut.value = `${adresseLigne1} - ${codePostal} ${ville}`);
                } else return '';
              });
            });
          }
        });

        // Put the name to lower case
        newState.map(el => {
          if (el.id === 'nom')
            el.value = el.value.charAt(0) + el.value.slice(1).toLowerCase();

          return el;
        });

        localStorage.setItem('signature', JSON.stringify(newState));
        localStorage.setItem('lastSignatureFetch', new Date());

        setState(newState);
      });
  } catch (err) {
    console.log('ERROR CATCHED :', err);
  }
}

const fetchUserRoles = async (lambdaUrl, setState) => {
  try {
    return await fetch(lambdaUrl + 'get-user-roles', {
      method: 'POST',
      // body: JSON.stringify({ fr: fr }),
    })
      .then(res => res.json())
      .then(res => {
        setState(res);
        return res;
      });
  } catch (err) {
    console.log('ERROR FETCHED :', err);
  }
};

const fetchUserRoleFromId = async (lambdaUrl, fonciaId, userAuth) => {
  try {
    return await fetch(lambdaUrl + 'get-user-roles-from-id', {
      method: 'POST',
      body: JSON.stringify({ fonciaId: fonciaId }),
    })
      .then(res => res.json())
      .then(res => {
        userAuth.role = res[0].role;
        userAuth.roles = res.map(role => {
          return role.role;
        });
        userAuth.brand = res[0].brand;
        return userAuth;
      });
  } catch (err) {
    console.log('ERROR FETCHED :', err);
  }
};

const fetchLecteurs = async (lambdaUrl, setState) => {
  return await fetch(lambdaUrl + 'get-lecteurs', {
    method: 'POST',
    // body: JSON.stringify({ fr: fr }),
  })
    .then(res => res.json())
    .then(res => {
      setState(res);
      return res;
    })
    .catch(err => {
      console.log('ERROR FETCHED :', err);
    });
};

const deleteLecteur = async (lambdaUrl, idLecteur) => {
  return await fetch(lambdaUrl + 'delete-lecteur', {
    method: 'POST',
    body: JSON.stringify({ fonciaId: 'fr', idLecteur: idLecteur }),
  })
    .then(res => res.json())
    .then(res => {
      return res;
    })
    .catch(err => {
      console.log('ERROR DELETED :', err);
    });
};

const deleteUserRole = async (lambdaUrl, idUserRole) => {
  return await fetch(lambdaUrl + 'delete-user-role', {
    method: 'POST',
    body: JSON.stringify({ fonciaId: 'fr', idUserRole: idUserRole }),
  })
    .then(res => res.json())
    .then(res => {
      return res;
    })
    .catch(err => {
      console.log('ERROR DELETED :', err);
    });
};

const addNewLecteur = async (lambdaUrl, fonciaId, lecteur, enqueueSnackbar) => {
  return await fetch(lambdaUrl + 'add-custom-network-reader', {
    method: 'POST',
    body: JSON.stringify({ fonciaId: fonciaId, lecteur: lecteur }),
  })
    .then(res => res.json())
    .then(res => {
      enqueueSnackbar('Lecteur réseau créé avec succes!', {
        variant: 'success',
        autoHideDuration: 3000,
      });
      return res;
    })
    .catch(err => {
      console.log('ERROR ADD :', err);
      enqueueSnackbar(
        "Une erreur est survenue lors de la tentative d'ajout du lecteur",
        {
          variant: 'error',
          autoHideDuration: 3000,
        }
      );
    });
};

const addNewUserRole = async (
  lambdaUrl,
  fonciaId,
  userRole,
  enqueueSnackbar
) => {
  return await fetch(lambdaUrl + 'add-user-role', {
    method: 'POST',
    body: JSON.stringify({ fonciaId: fonciaId, userRole: userRole }),
  })
    .then(res => res.json())
    .then(res => {
      enqueueSnackbar('Donnée ajoutée avec succes!', {
        variant: 'success',
        autoHideDuration: 3000,
      });
      return res;
    })
    .catch(err => {
      console.log('ERROR ADD :', err);
      enqueueSnackbar(
        "Une erreur est survenu lors de la tentative d'ajouté la donnée!",
        {
          variant: 'error',
          autoHideDuration: 3000,
        }
      );
    });
};

const updateLecteur = async (lambdaUrl, fonciaId, lecteur, enqueueSnackbar) => {
  return await fetch(lambdaUrl + 'update-lecteur', {
    method: 'POST',
    body: JSON.stringify({ fonciaId: fonciaId, lecteur: lecteur }),
  })
    .then(res => res.json())
    .then(res => {
      enqueueSnackbar('Donnée modifiée avec succes!', {
        variant: 'success',
        autoHideDuration: 3000,
      });
      return res;
    })
    .catch(err => {
      console.log('ERROR UPDATED :', err);
      enqueueSnackbar(
        'Une erreur est survenu lors de la tentative de modifié le lecteur!',
        {
          variant: 'error',
          autoHideDuration: 3000,
        }
      );
    });
};

const updateUserRole = async (
  lambdaUrl,
  fonciaId,
  userRole,
  enqueueSnackbar
) => {
  return await fetch(lambdaUrl + 'update-user-role', {
    method: 'POST',
    body: JSON.stringify({ fonciaId: fonciaId, userRole: userRole }),
  })
    .then(res => res.json())
    .then(res => {
      enqueueSnackbar('Donnée modifiée avec succes!', {
        variant: 'success',
        autoHideDuration: 3000,
      });
      return res;
    })
    .catch(err => {
      console.log('ERROR UPDATED :', err);
      enqueueSnackbar(
        'Une erreur est survenu lors de la tentative de modifier la donnée!',
        {
          variant: 'error',
          autoHideDuration: 3000,
        }
      );
    });
};
export {
  askInfo,
  fetchUserRoles,
  fetchUserRoleFromId,
  fetchLecteurs,
  addNewUserRole,
  addNewLecteur,
  deleteLecteur,
  deleteUserRole,
  updateLecteur,
  updateUserRole,
};
